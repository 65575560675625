import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import ReactGA from "react-ga";

import LoaderButton from "../components/LoaderButton";

import "./Login.css";

export default function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect( () => ReactGA.pageview('/login'), [] );

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading( true );

    try {
      let user = await Auth.signIn(username.toUpperCase(), password);
      console.log("Logged in",user);
      setIsLoading( false );
      props.setUserInfo( user.getSignInUserSession().getIdToken() );
      ReactGA.set({userId: user.attributes && user.attributes.sub})
      props.history.push( "/" );
    } catch (e) {
      alert(e.message);
      setIsLoading( false );
    }
  }

  return (
    <div className="Login">
      <img src="/img/power-bar-v01-849x116.png"/>
      <form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <Form.Control
            autoFocus
            type="text"
            placeholder="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Control
            placeholder="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          variant="warning"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Log In
        </LoaderButton>
      </form>
      <div className="accountRequest">
        <a href="https://abcaudio.com/programming/digital/abc-power-portal/" target="_blank">
          Click here for Power Portal information
        </a>
      </div>
    </div>
  );
}
