import React, {useState} from "react";
import {Card,Container,Row,Col} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TextCard from "../components/TextCard";

import "./Faq.css";
import IButton from "../components/IButton";

export function Contact(props){
  return(
    <a href="mailto:ABC.Power.Portal@abc.com?subject=Question+About+ABC+Power+Portal">ABC.Power.Portal@abc.com</a>
  )
}

export default function Faq(props){

  function QA({...props}){
    const [open,setOpen] = useState(false);

    return(
      <Card className="qa">

        <Card.Header className="qa-question" onClick={()=>setOpen(!open)}>
          <Container>
            <Row>
              <Col sm={11} className="qa-question-text">
                {props.children[0]}
              </Col>
              <Col sm={1} className="qa-question-icon">
                <FontAwesomeIcon icon="chevron-down" className={open?"icon icon-open":"icon icon-closed"}/>
              </Col>
            </Row>
          </Container>
        </Card.Header>

        <div className={`qa-answer collapse ${open?"show":""}`}>
          <Card.Body>
            {props.children[1]}
          </Card.Body>
        </div>

      </Card>
    )
  }

  function Question(props){
    return(
      <div className="question">
        {props.children}
      </div>
    )
  }

  function Answer(props){
    return(
      <div className="answer">
        {props.children}
      </div>
    )
  }

  return(
    <TextCard wide className="Faq accordion" title="Frequently Asked Questions">

      <QA>
        <Question>How do I get a demo login and password for ABC Power Portal?</Question>
        <Answer>
          Just fill out the form
          here: <a href="https://abcaudio.com/programming/digital/abc-power-portal/" target="_blank">
            https://abcaudio.com/programming/digital/abc-power-portal/
          </a>
        </Answer>
      </QA>

      <QA>
        <Question>How do I become an ABC affiliate?</Question>
        <Answer>
          Contact Affiliate Solutions: <Contact/>
        </Answer>
      </QA>

      <QA>
        <Question>
          Whom Do I Contact if I’ve Lost My Username and/or Password or if I Need My Login Changed?
        </Question>
        <Answer>
          If you need us to send you your login information or change your login,
          send an e-mail to <Contact/>.
          Make certain you include your name, station call letters and telephone number.
          Note that we will first verify the authenticity of all such requests before we comply,
          and that your ABC Power Portal login may subsequently be changed at our
          discretion even if you don’t request it. Because that’s how we roll.
        </Answer>
      </QA>

      <QA>
        <Question>
          Whom Do I Contact if I Need to Change My Contact Information?
        </Question>
        <Answer>
          If you need to change your ABC Power Portal contact information,
          such as if you have a personnel change, send an e-mail request to <Contact/>.
          As with login changes, we will first verify the authenticity of all such requests before we comply.
        </Answer>
      </QA>

      <QA>
        <Question>
          Whom Do I Contact if I Have a Question, Comment or Suggestion About ABC Power Portal Content?
        </Question>
        <Answer>
          Just send an e-mail to <Contact/>.
        </Answer>
      </QA>

      <QA>
        <Question>To Preview Video</Question>
        <Answer>
          Click the video or the
          info button <IButton icon="info"/>
          then click the play button <IButton icon="play"/>
        </Answer>
      </QA>

      <QA>
        <Question>To Download Video</Question>
        <Answer>
          <p>
            Click the download button <IButton icon="arrow-down" variant="warning"/> and save the file.
          </p>
          <p>
            If you are not able to download, you may have a "preview" account
            that does not allow downloads of full quality videos.
            You can upgrade by contacting <Contact/>
          </p>
        </Answer>
      </QA>

      <QA>
        <Question>To Copy the Video Description</Question>
        <Answer>
          Click the “copy” button next to the description to save it to your clipboard,
          or select and copy the text as usual. Hashtags will also be copied when using the copy button.
          You can then paste the copy to the destination social media site or your
          own website and customize it if needed.
        </Answer>
      </QA>

      <QA>
        <Question>To Find the Technical Details of the Video</Question>
        <Answer>
          Click the video or the info button  <IButton icon="info"/>.
          ABC Power Portal videos are created to share on most social media platforms,
          streaming services, webpages and apps.
        </Answer>
      </QA>

      <QA>
        <Question>At What Times Is ABC Power Portal Updated?</Question>
        <Answer>
          We update ABC Power Portal with music and breaking entertainment news throughout the day.
          ABC Power Portal is updated a little less frequently on weekends and major U.S. holidays.
        </Answer>
      </QA>

      <QA>
        <Question>Whom Do I Contact if I Have a Technical Problem?</Question>
        <Answer>
          <p>
            Not that we don’t love hearing from you, but before you contact
            ABC Power Portal Tech Support please ensure you’ve investigated possible
            problem causes on your end. Very often, issues with connecting to
            ABC Power Portal are caused by browser security settings,
            local network configuration, firewall and anti-virus settings, and so on.
            So have your tech geek take a peek before contacting our tech geek.
          </p>
          <p>
            If your problem persists, your best bet is always to email us at <Contact/>.
            We’ll get back to you straightaway.
          </p>

        </Answer>
      </QA>

      <QA>
        <Question>What If I Still Have Questions?</Question>
        <Answer>
          We don’t see how that could possibly be, but if you have a question that
          hasn't been addressed here, send an e-mail to <Contact/>
        </Answer>
      </QA>

    </TextCard>
  )
}