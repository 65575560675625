import React, {useEffect} from 'react';

export default function BarsRotate({fillColor="#ffffff",...props}) {
  const title = props.title || "bars rotate";

  useEffect(() => {

    let elements = document.getElementsByClassName( "nc-loop_bars-rotate-48" );
    let start = null;

    function animate( t ){
      start || (start = t);
      let n = t - start;
      800 > n || (start = start + 800);
      for( let co=0; co<elements.length; co++ ){
        elements[co].setAttribute( "transform", "rotate(" + parseInt( Math.min( n / 100, 8 ) ) % 8 * 45 + " 24 24)" );
      }

      animationId = requestAnimationFrame( animate );
    }

    let animationId = requestAnimationFrame( animate );

    return(() =>{
      if( animationId ){
        cancelAnimationFrame( animationId );
      }
    });
  }, []);

  return (
    <svg height="48" width="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill="#ffffff">
        <g className="nc-loop_bars-rotate-48" transform="rotate(270 24 24)">
          <rect height="10" width="6" fill={fillColor} x="21" y="0"/>
          <rect height="10" style={{"opacity":"0.4"}} width="6" fill={fillColor} transform="matrix(0.7071 0.7071 -0.7071 0.7071 18.435 -23.3762)" x="34.4" y="5.6"/>
          <rect height="6" style={{"opacity":"0.4"}} width="10" fill={fillColor} x="38" y="21"/>
          <rect height="10" style={{"opacity":"0.4"}} width="6" fill={fillColor} transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 90.3762 37.435)" x="34.4" y="32.4"/>
          <rect height="10" style={{"opacity":"0.4"}} width="6" fill={fillColor} x="21" y="38"/>
          <rect height="10" style={{"opacity":"0.4"}} width="6" fill={fillColor} transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -8.435 71.3762)" x="7.6" y="32.4"/>
          <rect height="6" style={{"opacity":"0.6"}} width="10" fill={fillColor} x="0" y="21"/>
          <rect height="10" style={{"opacity":"0.8"}} width="6" fill={fillColor} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.3762 10.565)" x="7.6" y="5.6"/>
        </g>
      </g>
    </svg>
  );
}
