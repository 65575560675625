import React, {useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import "./Home.css";
import Auth, {isDisabled, isEditor} from "../components/Auth";
import ItemList from "../components/ItemList";
import WaitTill from "../components/WaitTill";
import Login from "./Login";
import * as QueryString from "query-string"
import {ApiContext} from "../components/ApiContext";
import {Title} from "../components/Title";
import {Contact} from "./Faq";
import {Modal} from "react-bootstrap";

function HomePage({items,category,isLoading,isDone,loadMore,...props}){

  let header = "The Latest";
  if( items.category ){
    if( items.q ){
      header = `${items.category} Search Results: ${items.q}`
    } else {
      header = items.category;
    }
  } else if( items.q ){
    header = `Search Results: ${items.q}`;
  }

  return (
    <div className="lander">

      <WaitTill condition={items && items.type}>
        <Title>{header}</Title>

        <ItemList items={items.files} isLoading={isLoading} loadMore={loadMore} isDone={isDone} {...props}/>
      </WaitTill>

    </div>
  );
}

export default function Home({userInfo, search, ...props}) {
  const location = useLocation();
  const query = QueryString.parse( location.search );
  console.log('initialCategory',query.category);

  const api = useContext( ApiContext );

  const [isLoading,setIsLoading] = useState(false);
  const [isDone,setIsDone] = useState(false);
  const [items, setItems] = useState([]);
  const [category,setCategory] = useState(query.category);

  useEffect(() => {
    const l = category
      ? category.toLowerCase().replace( / /g, "_" )
      : 'undefined';
    window.localStorage.setItem( "category", l );
    console.log('store category',l);
  }, [category]);

  useEffect(() => {
    const {category} = QueryString.parse( location.search );
    console.log('effect category',category);
    setCategory( category );
  },[location]);

  function endpoint( userInfo ){
    const domain=userInfo.format;
    const ret = isEditor(userInfo) ?
      `/file/editor/${domain}/list` :
      `/file/${domain}/list`;
    return ret;
  }

  const doReload = useCallback( () => {
    function loadFiles(){
      let init = {
        queryStringParameters: {}
      };
      if( category ){
        init.queryStringParameters.category = category;
      }
      if( search ){
        init.queryStringParameters.q = search;
      }
      return api.get("media", endpoint(userInfo), init);
    }

    async function onLoad(){
      if( !userInfo || !(userInfo.format) ){return;}
      try {
        setIsLoading(true);
        const files = await loadFiles();
        console.log( "files", files );
        setItems( files );
        setIsLoading(false);

      } catch( e ){
        console.error( e );
      }
    }

    onLoad();
  }, [search,category,userInfo,api] );

  useEffect( () => {
    setItems([]);
    doReload();
  }, [search,category,userInfo,api,doReload] );

  const loadMore = useCallback(() => {
    function loadFiles( before ){
      console.log('loadFiles before',before);
      let init = {
        queryStringParameters: {
        }
      };
      if( category ){
        init.queryStringParameters.category = category;
      }
      if( before ){
        init.queryStringParameters.before = before;
      }
      return api.get("media", endpoint(userInfo), init);
    }

    async function onLoad(){
      if( !userInfo ){return;}
      try {
        setIsLoading(true);

        const oldFiles = (items && items.files) || [];
        const lastItem =  oldFiles[oldFiles.length-1];
        const files = await loadFiles( lastItem && lastItem.published );
        console.log( "new files", files );
        setIsLoading(false);

        if( !files.files || files.files.length === 0 ){
          console.log("done loading");
          setIsDone( true );
          setItems( items );

        } else {
          const allFiles = oldFiles.concat( files.files );
          files.files = allFiles;
          setItems( files );

        }
      } catch( e ){
        console.error( e );
      }
    }

    onLoad();
  });

  return (
    <div className="Home">
      <Auth preview userInfo={userInfo}>
        <HomePage
          items={items}
          userInfo={userInfo}
          isLoading={isLoading}
          loadMore={loadMore} isDone={isDone}
          doReload={isEditor(userInfo) && doReload}
        />
      </Auth>
      <Auth not preview userInfo={userInfo}>
        <Login {...props}/>
      </Auth>
      <Modal show={isDisabled(userInfo)} onHide={()=>props.setUserInfoState(null)}>
        <Modal.Header closeButton={true}>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your account has been disabled.
          </p>
          <p>
            Contact <Contact/> if you feel
            this is in error or to subscribe.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
}