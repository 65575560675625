import React from "react";
import {Modal} from "react-bootstrap";

import './XModal.css';

export function XModal({className, onHide, ...props}){
  return(
    <Modal className={`XModal ${className}`} show={true} onHide={onHide} centered={true} size="lg">
      <Modal.Header closeButton={true}>
      </Modal.Header>
      {props.children}
    </Modal>
  );
}