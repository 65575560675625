import './ReportConfig.css';

import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker/es";

class ReportType {
  constructor( file ){
    this.file = file;
  }

  get title(){
    const type = this.file.request['detail-type'];
    const simpleType = type.substring( "PortalReport-".length );
    return `${camelToHuman(simpleType)} report`;
  }

  get subtitles(){
    const from = this.file.request.detail.data.startDate;
    const to = this.file.request.detail.data.endDate;
    return [
      `${shortDateFormat(from)} - ${shortDateFormat(to)}`
    ]
  }

  get rows(){
    return this.file.report;
  }

  get key(){
    return null;
  }

  get columns(){
    return [];
  }

  isOption({...props}){
    return true;
  }

  controls({reportType, data, onUpdate, ...props}){
    return(
      <DateRangeEntry data={data} onUpdate={onUpdate}/>
    )
  }

  isValid({data, ...props}){
    return true;
  }

  buildMeta({data, ...props}){
    const startTime = moment(data.startDate).valueOf();
    const endTime   = moment(data.endDate).valueOf();
    return `${startTime}-${endTime}`;
  }

  fieldNames(){
    return [
      null,
      null,
      '#startTime',
      '#endTime'
    ]
  }

  extractMeta( fileEntry, namePart ){
    // Parts 0 and 1 are set outside this
    this.fieldNames().forEach( (typeField, index) => {
      if( typeField ){
        const type  = typeField.substr( 0, 1 );
        const field = typeField.substr( 1 );

        let value = (index < namePart.length) && namePart[index];
        if( value ){
          if( type === '#' ){
            try {
              value = Number.parseInt( value, 10 );
            } catch( x ){
              console.error( x );
            }
          }

          fileEntry[field] = value;
        }
      }
    });
  }

  typeName( fileEntry ){
    return camelToHuman( fileEntry.reportType );
  }
}
export const typeClass = {};
typeClass.ping = class extends ReportType{
  get key(){
    return "name"
  }

  get columns(){
    return [
      {
        dataField: "name",
        text: "Name",
        sort: true
      },
      {
        dataField: "value",
        text: "Value",
        sort: true
      }
    ]
  }

  isOption({...props}){
    return false;
  }

  controls({data, onUpdate, ...props}){
    return(
      <>
        {super.controls({data, onUpdate, ...props})}
        <div>Alpha</div>
        <div>Bravo</div>
      </>
    )
  }
}
typeClass.test = class extends ReportType{
  get key(){
    return "date"
  }

  get columns(){
    return [
      {
        dataField: "date",
        text: "Date",
        sort: true,
        formatter: longDateFormat,
        csvFormatter: csvDateFormat
      },
      {
        dataField: "value",
        text: "Value",
        sort: true
      }
    ]
  }

  isOption({...props}){
    return false;
  }

}
typeClass.user = class extends ReportType{
  get key(){return "username"}

  get columns(){
    return [
      {
        dataField: "username",
        text: "ID",
        sort: true
      },
      {
        dataField: "attributes.name",
        text: "Name",
        sort: true
      },
      {
        dataField: "attributes.email",
        text: "Email",
        hidden: true,
        csvExport: true
      },
      {
        dataField: "lastActivity",
        text: "Last Activity",
        sort: true,
        formatter: longDateTimeFormat,
        csvFormatter: csvDateTimeFormat
      },
      {
        dataField: "groups",
        text: "Type",
        sort: true,
        formatter: groupFormat,
        csvFormatter: groupFormat
      },
      {
        dataField: "attributes",
        text: "Formats",
        style: {whiteSpace: "pre"},
        formatter: formatFormat,
        csvFormatter: formatFormat
      },
      {
        dataField: "countList",
        text: "List",
        sort: true,
        align: "right",
        csvText: "Card List Views",
        csvType: Number
      },
      {
        dataField: "countDetail",
        text: "Detail",
        sort: true,
        align: "right",
        csvText: "Card Detail Views",
        csvType: Number
      },
      {
        dataField: "countPreview",
        text: "Preview",
        sort: true,
        align: "right",
        csvText: "Video Previews",
        csvType: Number
      },
      {
        dataField: "countDownload",
        text: "Download",
        sort: true,
        align: "right",
        csvText: "Video Downloads",
        csvType: Number
      },
      {
        dataField: "countPersonal",
        text: "Custom",
        sort: true,
        align: "right",
        csvText: "Custom Downloads",
        csvType: Number
      }
    ]

    function groupFormat( cell, row, rowIndex, extra ){
      const last = cell ? cell.length - 1 : -1;
      if( last >= 0 ){
        return cell[last];
      } else {
        return "";
      }
    }

    function formatFormat( cell, row, rowIndex, extra ){
      const prefix = "custom:format-";
      const formats = {};
      const keys = Object.keys( cell );
      keys.forEach( key => {
        if( key.indexOf(prefix) === 0 ){
          const format = key.substr(prefix.length);
          const val = cell[key];
          formats[format] = val;
        }
      });
      let ret = "";
      Object.keys( formats ).sort().forEach( key => {
        const val = formats[key];
        if( ret ){
          ret += "\n";
        }
        ret += `${key}:${val}`
      })
      return ret;
    }
  }
}
typeClass.downloadsForUser = class extends ReportType{

  get subtitles(){
    const ret = super.subtitles;
    ret.push( `User: ${this.file.request.detail.data.username}` );
    return ret;
  }

  get key(){
    return "when"
  }

  get columns(){
    return [
      {
        dataField: "targetInfo.title",
        text: "Title",
        sort: true
      },
      {
        dataField: "targetInfo.file",
        text: "File",
        sort: true,
      },
      {
        dataField: "method",
        text: "Download Method"
      },
      {
        dataField: "when",
        text: "When",
        sort: true,
        formatter: longDateTimeFormat,
        csvFormatter: csvDateTimeFormat
      },
      {
        dataField: "targetInfo",
        text: "Formats",
        style: {whiteSpace: "pre"},
        formatter: targetInfoCategoryFormat,
        csvFormatter: targetInfoCategoryFormat
      }
    ]
  }

  controls({data, onUpdate, ...props}){
    return(
      <>
        {super.controls({data, onUpdate, ...props})}
        <div className="usernameEntry">
          <span className="label">User Login</span>
          <input
            type="text"
            value={data.username}
            onChange={e => onUpdate({...data, username:e.target.value.toUpperCase()})}
          />
        </div>
      </>
    )
  }

  isValid({data, ...props}){
    return super.isValid({data, ...props}) && data.username;
  }

  buildMeta( {data, ...props} ){
    const s = super.buildMeta( {data, ...props} );
    return `${s}-${data.username}`;
  }

  fieldNames(){
    return [
      ...super.fieldNames(),
      "$username"
    ]
  }

  typeName( fileEntry ){
    return `${super.typeName( fileEntry )}: ${fileEntry.username}`;
  }
}
typeClass.downloadsForVideoCategory = class extends ReportType{

  get subtitles(){
    const ret = super.subtitles;
    ret.push( `Category: ${this.file.request.detail.data.category}` );
    return ret;
  }

  get key(){
    return "when"
  }

  get columns(){
    return [
      {
        dataField: "username",
        text: "User",
        sort: true,
      },
      {
        dataField: "targetInfo.title",
        text: "Title",
        sort: true
      },
      {
        dataField: "targetInfo.file",
        text: "File",
        sort: true,
      },
      {
        dataField: "method",
        text: "Download Method"
      },
      {
        dataField: "when",
        text: "When",
        sort: true,
        formatter: longDateTimeFormat,
        csvFormatter: csvDateTimeFormat
      },
      {
        dataField: "targetInfo",
        text: "Formats",
        style: {whiteSpace: "pre"},
        formatter: targetInfoCategoryFormat,
        csvFormatter: targetInfoCategoryFormat
      }
    ]
  }

  controls({data, onUpdate, categories, ...props}){
    const allCategories = new Set();
    Object.keys( categories ).forEach( format => {
      if( format !== 'ALL' ){
        const formatCategories = categories[format];
        formatCategories.forEach( cat => {
          allCategories.add( cat );
        })
      }
    })
    return(
      <>
        {super.controls({data, onUpdate, ...props})}
        <div className="categoryEntry">
          <span className="label">Category</span>
          <select
            value={data.category}
            onChange={e => onUpdate({...data, category: e.target.value})}
          >
            <option value="">(Select Category)</option>
            {
              [...allCategories].sort().map( cat => {
                return(
                  <option key={cat}>
                    {cat}
                  </option>
                )
              })
            }
          </select>
        </div>
      </>
    )
  }

  isValid({data, ...props}){
    return super.isValid({data, ...props}) && data && data.category;
  }

  buildMeta( {data, ...props} ){
    const s = super.buildMeta( {data, ...props} );
    return `${s}-${data.category}`;
  }

  fieldNames(){
    return [
      ...super.fieldNames(),
      "$category"
    ]
  }

  typeName( fileEntry ){
    return `${super.typeName( fileEntry )}: ${fileEntry.category}`;
  }

}
typeClass.downloadsForVideoFormat   = class extends ReportType{

  get subtitles(){
    const ret = super.subtitles;
    ret.push( `Format: ${this.file.request.detail.data.format}` );
    if( this.file.request.detail.data.category ){
      ret.push( `Category: ${this.file.request.detail.data.category}` );
    }
    return ret;
  }

  get key(){
    return "when"
  }

  get columns(){
    return [
      {
        dataField: "username",
        text: "User",
        sort: true,
      },
      {
        dataField: "targetInfo.title",
        text: "Title",
        sort: true
      },
      {
        dataField: "targetInfo.file",
        text: "File",
        sort: true,
      },
      {
        dataField: "method",
        text: "Download Method"
      },
      {
        dataField: "when",
        text: "When",
        sort: true,
        formatter: longDateTimeFormat,
        csvFormatter: csvDateTimeFormat
      },
      {
        dataField: "targetInfo",
        text: "Formats",
        style: {whiteSpace: "pre"},
        formatter: targetInfoCategoryFormat,
        csvFormatter: targetInfoCategoryFormat
      }
    ]
  }

  controls({data, onUpdate, categories, ...props}){
    return(
      <>
        {super.controls({data, onUpdate, ...props})}
        <div className="formatEntry">
          <span className="label">Format</span>
          <select
            value={data.format}
            onChange={e => onUpdate({...data, format: e.target.value})}
          >
            <option value="">(Select Format)</option>
            {
              Object.keys(categories).sort().map( format => {
                if( format === 'ALL' ){
                  return null;
                }
                return(
                  <option key={format}>
                    {format}
                  </option>
                )
              })
            }
          </select>
        </div>
        <div className="categoryEntry">
          <span className="label">Category</span>
          <select
            value={data.category}
            onChange={e => onUpdate({...data, category: e.target.value})}
            disabled={!data.format}
          >
            {
              data.format && <option value="">all</option>
            }
            {
              data.format && categories[data.format].sort().map( category => {
                return(
                  <option key={category}>{category}</option>
                )
              })
            }
          </select>
        </div>
      </>
    )
  }

  isValid({data, ...props}){
    return super.isValid({data, ...props}) && data && data.format;
  }

  buildMeta( {data, ...props} ){
    const s = super.buildMeta( {data, ...props} );
    let ret = [
      s,
      data.format
    ]
    if( data.category ){
      ret.push( data.category );
    }
    return ret.join('-');
  }

  fieldNames(){
    return [
      ...super.fieldNames(),
      "$format",
      "$category"
    ]
  }

  typeName( fileEntry ){
    let ret = `${super.typeName( fileEntry )}: ${fileEntry.format}`;
    if( fileEntry.category ){
      ret += ` (${fileEntry.category})`
    }
    return ret;
  }

}

export function datePatternFormat( pattern, cell, row, rowIndex, extra ){
  if( cell ){
    return moment(cell).local().format(pattern);
  } else {
    return "";
  }
}

export function longDateTimeFormat( cell ){
  return datePatternFormat( "ddd D MMM YYYY hh:mm:ss a", cell );
}

export function longDateFormat( cell ){
  return datePatternFormat( "ddd D MMM YYYY", cell );
}

export function shortDateFormat( cell ){
  return datePatternFormat( "D MMM YYYY", cell );
}

export function csvDateTimeFormat( cell ){
  return datePatternFormat( "MM/DD/YYYY HH:mm:ss", cell );
}

export function csvDateFormat( cell ){
  return datePatternFormat( "MM/DD/YYYY", cell );
}

function targetInfoCategoryFormat( cell ){
  const prefix = "cat_";
  const categories = [];
  const removeCategories = [ "", "none" ];
  Object.keys( cell ).forEach( key => {
    if( key.indexOf(prefix) === 0 ){
      // Remove non-category categories
      const v = cell[key] || [];
      const a = Array.isArray(v) ? v : [v];
      const val = a.filter( cat => cat && (removeCategories.indexOf(cat) === -1) );

      // This format is valid if there are any categories set
      if( val && val.length > 0 ){
        const category = key.substr(prefix.length);
        categories.push( category );
      }
    }
  })
  return categories.join("\n");
}

export function camelToHuman( camel ){
  return camel && camel.replaceAll( /[A-Z]/g, match => ` ${match.toLowerCase()}` );
}

export function getTypeInfo( reportType ){
  const cl = typeClass[reportType];
  const typeInfo = cl && new cl();
  return typeInfo;
}

function DateRangeEntry({data, onUpdate, ...props}){

  function setDateRange({start = data.startDate, end = data.endDate}){
    if( start > end ){
      [start, end] = [end, start];
    }
    const newData = {
      ...data,
      startDate: start,
      endDate: end
    }
    onUpdate( newData );
  }

  return (
    <div className="DateRangeEntry">
      <span className="dateEntryLabel">
        Date Range
      </span>
      <DatePicker
        selected={data.startDate}
        onChange={date => setDateRange({start:date})}
        selectsStart
        startDate={data.startDate}
        endDate={data.startDate}
        className="datePicker datePicker-start"
      />
      <span className="to">to</span>
      <DatePicker
        selected={data.endDate}
        onChange={date => setDateRange({end:date})}
        selectsEnd
        startDate={data.endDate}
        endDate={data.endDate}
        className="datePicker datePicker-end"
      />
    </div>
  )
}

export function ReportTypeSelect({value, onChange, ...props}){
  return(
    <div className="ReportTypeSelect">
      <span className="typeEntryLabel">
        New Report:
      </span>
      <select value={value} onChange={e => onChange(e.target.value)}>
        <option value="">(Select Report Type)</option>
        {
          Object.keys(typeClass)
            .sort()
            .map( type => {
              const typeInfo = getTypeInfo( type );
              if( typeInfo && typeInfo.isOption( props ) ){
                return (
                  <option key={type} value={type}>{camelToHuman(type)}</option>
                )
              } else {
                return null;
              }
            })
        }
      </select>
    </div>
  )
}

export function ReportConfig({reportType, data, onUpdate, ...props}){
  const typeInfo = getTypeInfo(reportType);
  return(
    <div className={`ReportConfig ReportConfig-${reportType}`}>
      {
        typeInfo && typeInfo.controls({reportType, data, onUpdate, ...props})
      }
    </div>
  )
}