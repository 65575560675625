import React, {useCallback, useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import Moment from "react-moment";
import BarsRotate from "./spinner/BarsRotate";
import LoaderButton from "./LoaderButton";

import "./LogList.css";
import {API} from "aws-amplify";
import {Title} from "./Title";

function key(item){
  return item.when+item.username;
}

function Log({className="",item,index,...props}){
  let rowIndex = index%2;
  return(
    <Row className={`Log row${rowIndex} ${className}`}>
      <Col sm={2} className="user">
        {item.username}
      </Col>
      <Col sm={3} className="datetime">
        <Container>
          <Row>
            <Col sm={6} className="date">
              <Moment local format="DD MMM YYYY" date={item.when} />
            </Col>
            <Col sm={6} className="time">
              <Moment local format="hh:mm:ss a" date={item.when} />
            </Col>
          </Row>
        </Container>
      </Col>
      <Col sm={2} className="method">
        {item.method}
      </Col>
      <Col sm={5} className="target">
        {item.target}
      </Col>
    </Row>
  )
}

export function LogCard({title,className="",items,isLoading,loadMore,...props}){

  const loadBefore = useCallback( () => {
    const before = items && items.length ? items[items.length-1].when : null;
    if( before ){
      loadMore({before});
    } else {
      loadMore({});
    }
  }, [items,loadMore]);

  const loadAfter = useCallback( () => {
    const after = items && items.length ? items[0].when : null;
    if( after ){
      loadMore({after})
    } else {
      loadMore({});
    }
  }, [items,loadMore]);

  return(
    <div className={`LogCard ${className}`}>
      <Title>{title}</Title>
      <Card>
        <Card.Header>
          <LoaderButton onClick={loadAfter} isLoading={isLoading}>Load Later</LoaderButton>
        </Card.Header>
        <Card.Body>
          {isLoading && (!items || !items.length) && <div className="loading"><BarsRotate fillColor="#000000"/></div>}
          {items && items.map( (item, index) => <Log item={item} index={index} key={key(item)} {...props}/> )}
        </Card.Body>
        <Card.Footer>
          <LoaderButton onClick={loadBefore} isLoading={isLoading}>Load Earlier</LoaderButton>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default function LogList({title,className,username,target,...props}){
  const [items,setItems] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const loadMore = useCallback( ({before,after}) => {

    function loadLog(){
      const params = {};
      if( username ){
        params.username = username;
      } else if( target ){
        params.target = target;
      }

      if( before ){
        params.before = before;
      }
      if( after ){
        params.after = after;
      }

      const init = {
        queryStringParameters: params
      };
      return API.get( "media", "/admin/log", init );
    }

    async function onLoad(){
      try{
        setIsLoading( true );
        const result = await loadLog();
        console.log( result );
        let newItems;
        if( !result.entries || !result.entries.length ){
          console.log("empty");
          newItems = items;

        } else if( before ){
          console.log('before');
          newItems = items.concat(result.entries);

        } else if( after ){
          console.log('after');
          newItems = result.entries.concat(items);

        } else {
          newItems = result.entries;
        }
        console.log('newItems',newItems);
        setItems( newItems );
      } catch( e ){
        console.error( e );
      }
      setIsLoading( false );
    }

    onLoad();

  },[username,target,items]);

  useEffect( () => {
    loadMore({});
  }, [username,target] );


  return(
    <div className={`LogList ${className}`}>
      <LogCard title={title} items={items} loadMore={loadMore} isLoading={isLoading} />
    </div>
  )

}