import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {Button, Card, Col, Container, InputGroup, Row} from "react-bootstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./User.css";
import WaitTill from "../../components/WaitTill";
import LoaderButton from "../../components/LoaderButton";
import {ApiContext} from "../../components/ApiContext";
import ButtonRow from "../../components/ButtonRow";
import {isAdmin, isEditor, isManager} from "../../components/Auth";
import {Title} from "../../components/Title";
import {formatTitles, validFormat} from "../../App";

import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";
import IButton from "../../components/IButton";
import Moment from "react-moment";

export const allGroups = [
  "admin",
  "editor",
  "manager"
];

export const managerGroups = [
];

function usernameOk( user ){
  return user.username && user.username.length > 0 &&
    user.username.match(/^[-A-Z0-9]+$/);
}

function passwordMatchOk( user ){
  return (user.password && user.password.length > 0) &&
    (user.passtest && user.passtest.length > 0) &&
    user.password === user.passtest;
}

function passwordLengthOk( user ){
  return user.password && user.password.length >= 8;
}

function passwordUpperCaseOk( user ){
  return user.password && user.password.match(/[A-Z]/);
}

function passwordLowerCaseOk( user ){
  return user.password && user.password.match(/[a-z]/);
}

function passwordNumberOk( user ){
  return user.password && user.password.match(/[0-9]/);
}

function passwordOk( user ){
  return passwordMatchOk( user ) &&
    passwordLengthOk( user ) &&
    passwordUpperCaseOk( user ) &&
    passwordLowerCaseOk( user ) &&
    passwordNumberOk( user );
}

function passwordEntered( user ){
  return (user.password && user.password.length > 0) ||
  (user.passtest && user.passtest.length > 0)
}

function emailOk( user ){
  return user.email && user.email.length > 0 &&
    user.email.match(/^\S+@\S+$/);
}

function Met({test, ...props}){
  let isMet = false;
  let icon = <FontAwesomeIcon icon="times-circle" color="red"/>;

  if( test ){
    isMet = true;
    icon = <FontAwesomeIcon icon="check-circle" color="green"/>;
  }

  return(
    <div className={`Met Met-${isMet}`}>
      <span className="Met-icon">{icon}</span>
      <span className="Met-text">{props.children}</span>
    </div>
  )
}

function Input({ label, name, onChange, user, readOnly=false, type="text", ...props }){

  let input = "";
  if( user && name ){
    if( readOnly ){
      input = <div>{user[name]}</div>;
    } else {
      input = <input type={type} name={name} onChange={onChange} value={user[name]||""} />;
    }
  }

  return(
    <Row key={`Row-${name}`}>
      <Col sm={3}>
        {label}
      </Col>
      <Col sm={6}>
        {input}
        <div className="Input-children">
          {props.children}
        </div>
      </Col>
    </Row>
  )
}

function Groups({ label, onChangeGroup, onChangeFormat, user, isNew, groups, categories, ...props }){
  let formatGroup = '';
  const formats = Object.keys( categories );
  for( let co=0; user.groups && !formatGroup && co<formats.length; co++ ){
    const format = formats[co];
    if( user.groups.indexOf( `${format}-user` ) >= 0 ){
      formatGroup = `${format}-user`;
    } else if( user.groups.indexOf( `${format}-preview`) >= 0 ){
      formatGroup = `${format}-preview`;
    }
  }
  console.log('formatGroup',groups,formatGroup);
  return(
    <Row key={`Row-Groups`}>
      <Col sm={3}>
        {label}
      </Col>
      <Col>
        {
          groups.map(group =>{
            let isChecked = user && user.groups && user.groups.indexOf( group ) >= 0;
            return(
              <div key={group}>
                <input id={`group-${group}`} name={group} value={group}
                       type="checkbox"
                       onChange={onChangeGroup} checked={isChecked}/>
                <label htmlFor={`group=${group}`}>{group}</label>
              </div>
            )
          })
        }
        <select onChange={onChangeFormat} value={formatGroup}>
          <option key="group-none"></option>
        {
          Object.keys( categories ).map(format =>{
            return(
              <>
                <option key={`group-${format}-user`} value={`${format}-user`}>{format} user</option>
                <option key={`group-${format}-preview`} value={`${format}-preview`}>{format} preview</option>
              </>
            )
          })
        }
        </select>
      </Col>
    </Row>
  )
}

function Format({ format, onChange, user, ...props }){
  const formats = user.formats || {};
  const value = formats[format] || "";
  console.log('Format',formats,format,value);
  return(
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text>{formatTitles[format] || format}</InputGroup.Text>
      </InputGroup.Prepend>
      <select onChange={e => onChange( e.target.value, format )} value={value} className="custom-select">
        <option></option>
        <option value="user">User</option>
        <option value="preview">Preview</option>
      </select>
    </InputGroup>
  )
}

const accessGroups = [
  "user",
  "editor",
  "manager",
  "admin"
];

function Expires({user, onChange, ...props }){
  console.log('Expires user',user);
  const expires = user.expires;
  const expiring = user.groups && user.groups.includes('expiring');
  const expiresDate = expires ? new Date( expires+' 00:00:00' ) : null;

  function ExpiresString(){
    if( expires ){
      return(
        <>
          {expiring ? "Expires" : "Expired"} on <Moment date={expiresDate} format="MM/DD/YYYY"/>
        </>
      )
    } else {
      return "None"
    }
  }

  return(
    <Row className="Expires">
      <Col sm={3}>
        Expiration
      </Col>
      <Col>
        <ExpiresString/>
        <DatePicker
          selected={expiresDate}
          onChange={onChange}
          customInput={<IButton icon="pen" />}
        />
        {expires && <IButton icon="times" onClick={() => onChange(null)}/>}
      </Col>
    </Row>
  );
}

function AccessGroup({ onChange, user, ...props }){
  const groups = (user && user.groups) || [];
  let accessGroup = "";
  for( let co=accessGroups.length - 1; co >= 0 && !accessGroup; co-- ){
    if( groups.indexOf( accessGroups[co] ) >= 0 ){
      accessGroup = accessGroups[co];
    }
  }
  return(
    <Row>
      <Col sm={3}>
        Access
      </Col>
      <Col>
        <select onChange={e=> onChange( e.target.value )} value={accessGroup}>
          <option value="">none</option>
          {accessGroups.map(group => <option key={`accessgroup-${group}`}>{group}</option>)}
        </select>
      </Col>
    </Row>
  )
}

function FormatGroup({categories, user, onChange, ...props}){
  if( isEditor(user) || isManager(user) || !user.groups ){
    return null;
  }

  return(
    <Row className="format-group">
      <Col sm={3}>

      </Col>
      <Col>
        {
          Object.keys( categories )
            .filter(validFormat)
            .sort()
            .map(
            format => <Format key={`format-${format}`} format={format} user={user} onChange={onChange}/>
          )
        }
      </Col>
    </Row>
  )
}

export function User({isNew = false, userInfo, categories, ...props}){
  let { username } = useParams();

  const api = useContext( ApiContext );

  let initialState = {};
  if( isNew ){
    initialState = {
      groups: [],
      formats: {},
      expires: ""
    }
  }
  const [user, setUser] = useState( initialState );

  const [changed,setChanged] = useState(false);
  const [isLoading,setLoading] = useState(false);

  const groups = isAdmin( userInfo ) ? allGroups : managerGroups;

  useEffect( () => {

    function loadUser(){
      return api.get("media", "/admin/user", {
        queryStringParameters: {
          username: username
        }
      });
    }

    async function onLoad(){
      try {
        const loaded = await loadUser();
        console.log("User",loaded);
        if( loaded ){
          let attributes = (loaded && loaded.attributes) || {};
          let newUser = {
            username: loaded.username,
            name:     attributes.name,
            email:    attributes.email,
            expires:  attributes['custom:expires'] || '',
            groups:   loaded.groups,
            formats:  loaded.formats
          };
          console.log("onLoad newUser",newUser);
          setUser( newUser );
        }
      } catch( e ){
        console.error( e );
      }
    }

    if( !isNew ){
      onLoad()
    }
  }, [isNew,username,api] );

  function updateUser( e ){
    let name = e.target.name;
    let value = e.target.value;

    if( name === 'username' ){
      value = value.toUpperCase();
    }

    let newuser = {
      ...user,
      [name]: value
    };
    setUser(newuser);
    setChanged(true);
  }

  function updateExpires( expiresDate ){
    console.log("User updateExpires", expiresDate, typeof expiresDate)
    // Set, or clear, the expires attribute
    const expires = expiresDate ? expiresDate.toISOString().substring(0,10) : "";
    console.log('User expires', expires)

    // Add the "expriring" group if necessary
    let groups = user.groups || [];
    if( expires && !groups.includes('expiring') ){
      // We set a date, so make suer we set the group
      groups.push( 'expiring' );
    } else if( !expires && groups.includes('expiring') ){
      // We have cleared the date, so make sure we clear the expiring group
      groups = groups.filter( g => g !== 'expiring' );
    }

    // Set the user state
    let newUser = {
      ...user,
      groups,
      expires
    }
    setUser( newUser );
    setChanged( true );
  }

  function updateGroup( value ){
    let groups = user.groups || [];
    groups = groups.filter( group => accessGroups.indexOf(group) === -1 );

    // Add this value, if it is set
    if( value ){
      groups.push( value );
    }

    // Set the user state
    let newUser = {
      ...user,
      groups
    };
    setUser(newUser);
    setChanged(true);
  }

  function updateFormat( value, format ){
    let formats = user.formats || {};
    formats[format] = value;

    let newUser = {
      ...user,
      formats
    };
    setUser(newUser);
    setChanged(true);
  }

  function validateForm(){
    if( isNew ){
      return usernameOk( user ) && passwordOk( user ) && emailOk( user );

    } else {
      // Editing the user, so only test the passwords if they have changed
      let ok = changed && emailOk( user );
      if( ok && passwordEntered(user) ){
        ok = passwordOk( user );
      }
      return ok;
    }
  }

  async function submit(){
    try{
      let endpoint = isNew
        ? "/admin/user/new"
        : "/admin/user/edit";

      if( !user.formats ){
        user.formats = {};
        Object.keys(categories).forEach( format => {
          if( format !== 'ALL' ){
            user.formats[format] = "";
          }
        })
      }

      if( !user.groups ){
        user.groups = [""];
      }

      console.log(`${isNew ? "Creating" : "Saving"} user:`,user);
      let response = await api.post( "media", endpoint, {
        body: user
      });
      console.log('response',response);
      return response;
    }catch( error ){
      console.error( error );
    }
  }

  async function handleSubmit( event ){
    event.preventDefault();
    setLoading( true );
    let response = await submit();
    setLoading( false );
    if( response && response.username ){
      props.history.push( `/admin/user/${response.username}` );
    }
  }

  let className = `User ${isNew ? "isNew" : "isEdit"}`;

  return(
    <div className={className}>
      {
        isNew
        ? <Title>New User</Title>
        : <Title>User: {username}</Title>
      }
      <WaitTill condition={isNew || (user && user.username)}>
        {
          console.log("WaitTill condition", user && user.username)
        }
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <Container>
                <Input label="Username" name="username" readOnly={!isNew} onChange={updateUser} user={user}>
                  {
                    isNew &&
                      <>
                        <Met test={usernameOk(user)}>Letters, numbers, and - only</Met>
                      </>
                  }
                </Input>

                <Input label="Password" name="password" type="password" onChange={updateUser} user={user}>
                  {
                    (isNew || passwordEntered(user)) &&
                      <>
                        <Met test={passwordLengthOk(user)}>At least 8 characters</Met>
                        <Met test={passwordUpperCaseOk(user)}>At least one uppercase letter</Met>
                        <Met test={passwordLowerCaseOk(user)}>At least one lowercase letter</Met>
                        <Met test={passwordNumberOk(user)}>At least one number</Met>
                      </>
                  }
                </Input>
                <Input label="Password again" name="passtest" type="password" onChange={updateUser} user={user}>
                  {
                    (isNew || passwordEntered(user)) &&
                    <Met test={passwordMatchOk(user)}>Passwords entered match</Met>
                  }
                </Input>

                <Input label="Name"  name="name" onChange={updateUser} user={user}/>
                <Input label="Email" name="email" onChange={updateUser} user={user}>
                  <Met test={emailOk(user)}>Valid email format with no spaces</Met>
                </Input>

                <Expires user={user} onChange={updateExpires} />

                <AccessGroup user={user} onChange={updateGroup}/>

                <FormatGroup user={user} categories={categories} onChange={updateFormat}/>

                <ButtonRow>
                  <Button variant="outline-secondary" href="/admin/user">Cancel</Button>
                  <LoaderButton type="submit" isLoading={isLoading} disabled={!validateForm()}>
                    {isNew ? "Create" : "Update"}
                  </LoaderButton>
                </ButtonRow>
              </Container>
            </form>
          </Card.Body>
        </Card>
      </WaitTill>
    </div>
  )
}

export function UserNew(props){
  return(
    <User isNew={true} {...props}/>
  )
}
