import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Navbar} from "react-bootstrap";

import {Title} from "../../components/Title";
import {ApiContext} from "../../components/ApiContext";

import {Storage} from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import {useLocation, useParams, useRouteMatch} from "react-router-dom";
import WaitTill from "../../components/WaitTill";
import {TableSearch} from "../../components/TableSearch";
import {TableExportButton} from "../../components/TableExportButton";
import {typeClass} from "./ReportConfig";

import "./ReportView.css";

export default function ReportView({...props}){

  const {pathname} = useLocation();
  const reportIndex = pathname.indexOf('report');
  const fileKey = pathname.substring( reportIndex );
  console.log('fileKey',fileKey);

  const [file,setFile] = useState(null);

  useEffect( () => {
    Storage.get( fileKey, {download:true, level:'private' } )
      .then( result => {
        const fileStr = result.Body.toString('utf-8');
        const file = JSON.parse( fileStr );
        console.log("file=",file);

        const type = file.request['detail-type'];
        const simpleType = type.substring( "PortalReport-".length );
        const fileClass = typeClass[simpleType];
        const cl = new fileClass(file);
        setFile( cl );
      } )
  }, [] )

  const numRows = (file && file.rows && file.rows.length) || 0;

  return (
    <div className={`ReportView`}>
      <Title>{(file && file.title) || "Report"}</Title>
      {
        file && file.subtitles.map( subtitle => <h2>{subtitle}</h2> )
      }
      <WaitTill condition={file}>
        {
          file &&
          <ToolkitProvider bootstrap4 data={file.rows} columns={file.columns} keyField={file.key} exportCSV search>
            {
              props =>
                <Card>
                  <Card.Header>
                    <Navbar className="justify-content-between">
                      <div>
                        <TableSearch {...props.searchProps} />
                      </div>
                      <div className="resultSize">
                        {numRows} {numRows === 1 ? "result" : "results"}
                      </div>
                      <TableExportButton {...props.csvProps}/>
                    </Navbar>
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable {...props.baseProps}/>
                  </Card.Body>
                </Card>
            }
          </ToolkitProvider>
        }
      </WaitTill>
    </div>
  )
}