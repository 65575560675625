import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";
import Ping from "./containers/Ping";
import Contact from "./containers/Contact";
import Faq from "./containers/Faq";
import AdminRoutes from "./containers/admin/Routes";
import EditorRoutes from "./containers/editor/Routes";
import UserRoutes from "./containers/user/Routes";
import NotFound from "./containers/NotFound";
import About from "./containers/About";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
      <AppliedRoute path="/ping"  exact component={Ping} appProps={appProps} />
      <AppliedRoute path="/about" exact component={About} appProps={appProps} />
      <AppliedRoute path="/contact" exact component={Contact} appProps={appProps} />
      <AppliedRoute path="/faq" exact component={Faq} appProps={appProps} />

      <AppliedRoute path="/editor" component={EditorRoutes} appProps={appProps} />
      <AppliedRoute path="/admin"  component={AdminRoutes}  appProps={appProps} />
      <AppliedRoute path="/user"   component={UserRoutes}   appProps={appProps} />

      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}