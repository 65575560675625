import React, {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";
import {Badge, Button, Card} from "react-bootstrap";
import fileSize from "filesize";
import Moment from "react-moment";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga";


import Auth, {isEditor, isUser} from "../components/Auth";
import IButton from "../components/IButton";
import Vid from "../components/Vid";
import WaitTill from "../components/WaitTill";
import BarsRotate from "../components/spinner/BarsRotate";
import {ApiContext} from "../components/ApiContext";
import ButtonRow from "../components/ButtonRow";
import Duration from "../components/Duration"

import "./File.css";
import {Title} from "../components/Title";
import Download from "./user/Download";
import moment from "moment";

function Container({className="",...props}){
  return (
    <div className={`container ${className}`} {...props}>
      {props.children}
    </div>
  );
}

function Category({format,category,pinned}){
  if( !category ){
    category = [];
  }

  // Make sure the category list is an array
  if( !Array.isArray(category) ){
    category = [category];
  }

  // Eliminate any empty strings or nulls
  category = category.reduce( (acc,val) => {
    if( val ){
      acc.push( val );
    }
    return acc;
  }, [] );

  if( category.length === 0 ){
    category = ['none'];
  }

  return(
    <div>
      {format}:
      {pinned && "BREAKING - "}
      {category
        .map( (cat, index) => {
          return (<>
            {index > 0 ? ", " : ""}
            {cat}
          </>)
        })
      }
    </div>
  )
}

function Row({className="", name, ...props}){
  return (
    <div className={`row ${className}`} {...props}>
      {!name ? props.children :
        <>
          <Key>{name}</Key>
          <Val>{props.children}</Val>
        </>
      }
    </div>
  )
}

function Key({className="", ...props}){
  return (
    <div className={`col col-12 col-sm-3 ${className}`} {...props}>
      {props.children}
    </div>
  );
}

function Val({className="", ...props}){
  return (
    <div className={`col col-12 col-sm-9 ${className}`} {...props}>
      {props.children}
    </div>
  );
}

function Pending(props){
  return(
    <FontAwesomeIcon icon="spinner" spin={true}/>
  )
}

function FileState({state,playing,setPlaying,started,setStarted,doPause,doPlay,userInfo,...props}){

  let copyText = state.desc;
  if( state.tags && state.tags.length ){
    copyText = copyText + ' ' + state.tags.join(' ');
  }

  const [copied,setCopied] = useState(false);
  console.log('FileState render',state);

  let workflow = state.workflow;
  if( (!workflow || workflow === 'available') && moment(state.published).isAfter() ){
    workflow = 'scheduled';
  }

  return (
    <div className="File">
      <Title>{state.title || "Details"}</Title>
      <WaitTill condition={state.title}>
        <Card>
          <Card.Header>
            {
              state.thumbnail
                ? <Vid
                  videoId={`video-${state.id}`}
                  src={state.preview}
                  thumb={state.thumbnail}
                  playing={playing} setPlaying={setPlaying}
                  started={started} setStarted={setStarted}
                />
                : <div className="pending"><BarsRotate fillColor="black"/></div>
            }
          </Card.Header>
          <Card.Body>
            <div className="buttons">
              {playing
                ? <IButton icon="pause" onClick={e => doPause()} />
                : <IButton icon="play"  onClick={e => doPlay()}  />
              }
              <IButton
                icon="arrow-down"
                variant="warning"
                href={`#${state.id}.`}
              />
            </div>
            <Container>
              <Auth editor userInfo={userInfo}>
                {state.processing ? <Row className="processing">
                  <div className="col col-12">
                    <Badge variant="warning"><Pending/> PROCESSING</Badge>
                  </div>
                </Row> : null}
              </Auth>
              <Container className="FileDetails">
                <Row name="Title">{state.title}</Row>
                <Row name="Description">
                  <div className="container description-container">
                    <div className="row">
                      <div className="col col-12 col-sm-10">
                        {state.desc}
                        {
                          state.tags && state.tags.length &&
                          <div className="tags">
                            {state.tags.join(' ')}
                          </div>
                        }
                      </div>
                      <div className={`col col-1 col-sm-2 align-items-center pr-2 copied-${copied}`}>
                        <CopyToClipboard text={copyText} onCopy={()=>setCopied(true)}>
                        <span className={`badge text-uppercase ${copied?"badge-success":"badge-secondary"}`}>
                          {copied?"copied":"copy"}
                        </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row name="Category">
                  {
                    isEditor(userInfo)
                      ? Object.keys(state.category||[]).map( format =>
                        <Category
                          key={`category-${format}`}
                          format={format}
                          category={state.category[format]}
                          pinned={state.pinned}
                        />
                      )
                      : state.category[userInfo.format]
                  }
                </Row>
                <Row name="Date">
                  <Moment date={state.published || state.created} format="MM/DD/YYYY"/>
                </Row>
                <Row name="Duration">
                  {
                    state.duration
                      ? <Duration ms={state.duration}/>
                      : <Pending/>
                  }
                </Row>
                <Row name="Dimensions">
                  {
                    state.width && state.height
                      ? <span>{state.width} x {state.height}</span>
                      : <Pending/>
                  }

                </Row>
                <Auth editor userInfo={userInfo}>
                  <Row name="Created">
                    {state.created && <Moment local date={state.created} format="ddd D MMM YYYY h:mm:ss a"/>}
                    {state.createdBy && " by "+state.createdBy}
                  </Row>
                  <Row name="Changed">
                    {state.updated && <Moment local date={state.updated} format="ddd D MMM YYYY h:mm:ss a"/>}
                    {state.updatedBy && " by "+state.updatedBy}
                  </Row>
                  <Row name="Published">
                    {state.published && <Moment local date={state.published} format="ddd D MMM YYYY h:mm:ss a"/>}
                    {state.created !== state.published && " (modified)"}
                  </Row>
                  <Row name="Workflow"><span class="text-capitalize"><b>{workflow}</b></span></Row>
                </Auth>
                <Row name="File name">{state.filename}</Row>
                <Row name="File size" className="last-row">
                  {
                    state.filesize
                      ? fileSize(state.filesize)
                      : <Pending/>
                  }
                </Row>
                <Auth editor userInfo={userInfo}>
                  <ButtonRow className="last-row">
                    <Button variant="outline-danger" href={`/editor/delete/${state.id}`}>Delete</Button>
                    <Button variant="primary" href={`/editor/file/${state.id}`}>Edit</Button>
                  </ButtonRow>
                </Auth>
              </Container>
            </Container>
          </Card.Body>
        </Card>
      </WaitTill>
    </div>
  )
}

export function FileLoading(props){
  return(
    <div className="FileLoading">
      <BarsRotate fillColor="#000000"/>
    </div>
  )
}

export default function File({initialState={},loadState,userInfo,...props}) {
  let {id} = useParams();
  console.log('File idParam=',id,' loadState=',loadState, 'props=', props);
  if( loadState ){
    id = loadState;
  }
  const [state, setState] = useState(initialState);

  const [started, setStarted] = useState( false );
  const [playing, setPlaying] = useState( false );

  const api = useContext( ApiContext );

  function doPlay(){
    setStarted( true );
    setPlaying( true );
    ReactGA.pageview(`/preview/${state.title||state.id}`);
  }

  function doPause(){
    setPlaying( false );
  }

  useEffect(() => {

    let reloadTimeout = null;

    function loadFile() {

      const domain = isEditor(userInfo) ? "ALL" : userInfo.format;

      if( !isEditor(userInfo) && state && state.id && !state.processing ){
        // We don't need to load anything, so log that we're doing this,
        // but ignore what comes back.
        let params = {
          queryStringParameters: {
            id: state.id
          }
        };
        api.head( "media", `/file/${domain}`, params );

        // Instead, just return the state we have.
        return state;
      }

      // We don't have everything, so we'll load (or possibly reload) the state
      console.log('Loading from server');
      let params = {
        queryStringParameters: {
          id: id || state.id
        }
      };
      return api.get( "media", `/file/${domain}`, params );
    }

    async function onLoad() {
      try {
        const file = await loadFile();
        console.log( "file", file );
        setState( file );
        if( !file.errorMessage && file.processing ){
          reloadTimeout = setTimeout( onLoad, 5000 );
        }
        ReactGA.pageview(`/info/${file.title||file.id}`);
      } catch (e) {
        console.error(e);
      }
    }

    onLoad();
    return function cleanup(){
      if( reloadTimeout != null ){
        clearTimeout( reloadTimeout );
      }
    }
  }, [api,id,userInfo]);

  if( state && state.errorMessage ){
    return(
      <div className="File FileError">
        <h1>{state.errorMessage}</h1>
      </div>
    )

  } else if( state && state.id ){
    return(
      <FileState
        state={state}
        playing={playing} setPlaying={setPlaying}
        started={started} setStarted={setStarted}
        doPause={doPause} doPlay={doPlay}
        userInfo={userInfo}
        {...props}
      />
    );

  } else {
    return(<FileLoading/>);

  }

}