import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';

import ReactGA from 'react-ga';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from './config';
import './index.css';
import App from './App';
import {ApiContextProvider} from "./components/ApiContext";

const amplifyConf = {
  Auth: {
    mandatorySignIn:     true,
    region:              config.cognito.region,
    userPoolId:          config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    identityPoolId:      config.cognito.identityPoolId
  },
  Storage: {
    region:              config.s3.region,
    bucket:              config.s3.bucket,
    identityPoolId:      config.cognito.identityPoolId
  },
  API: {
    endpoints: [
      {
        name: "media",
        endpoint: config.api.url,
        region:   config.api.region,
        /*custom_header: async () => {
          let ret = {};
          let session = await Auth.currentSession();
          console.log('isValid',session.isValid());
          let bearerToken = session.getIdToken().getJwtToken();
          console.log('Custom header', bearerToken);
          ret.Authorization = `Bearer ${bearerToken}`;
          return ret;
        }*/
      }
    ]
  }
};
console.log(amplifyConf);
Amplify.configure(amplifyConf);

toast.configure({
  autoClose: false,
  position: toast.POSITION.TOP_RIGHT
});

ReactGA.initialize( config.ga.id, {
  debug: true,
  gaOptions: {
    sampleRate: 100
  }
});

ReactDOM.render(
  <ApiContextProvider>
    <Router>
      <App />
    </Router>
  </ApiContextProvider>
  ,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
