import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./IButton.css";

export default function IButton({icon, className="", ...props}){
  return (
    <Button className={`IButton IButton-${icon} ${className}`} {...props}>
      <FontAwesomeIcon icon={icon}/>
    </Button>
  );
}
