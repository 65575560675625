import React, {useState,useEffect} from "react";
import {useLocation,useHistory} from "react-router-dom";
import {Card, CardDeck, Modal, Container,Row,Col} from "react-bootstrap";
import Dotdotdot from "react-dotdotdot";
import ReactGA from "react-ga";

import IButton from "./IButton";

import File from "../containers/File";
import Duration from "./Duration";

import "./ItemList.css";
import InfiniteScroll from "./InfiniteScroll";
import {isUser} from "./Auth";
import BarsRotate from "./spinner/BarsRotate";
import * as moment from "moment";
import Moment from "react-moment";
import {XModal} from "./XModal";
import Download from "../containers/user/Download";

function ItemCard({
  item,
  userInfo,
  ...props
}){
  let href=`#${item.id}`;

  let category = (item && item.category && item.category[userInfo.format]) || [];
  if( !Array.isArray(category) ){
    category = [category];
  }
  category = category.reduce( (acc,val) => {
    if( val ){
      acc.push( val )
    }
    return acc;
  }, [] );

  const workflow = item.workflow;
  const workflowAvailable = !workflow || workflow === 'available';
  const now = moment();
  const tomorrow = moment().add(1,'day');
  const publishDate = moment( item.published );
  const pending = workflowAvailable && publishDate.isAfter( now );
  const pendingSoon  = pending && publishDate.isBefore( tomorrow );
  const pendingLater = pending && !pendingSoon;
  return (
    <Card>
      <a href={href}>
        <Card.Img variant="top" src={item.thumbnail}/>
      </a>
      <Card.Body>
        <div className="info category-breaking">
          <Container>
            <Row>
              <Col sm={6} className="category">
                {
                  category.map( (cat,index) => {
                    return(<>
                      {index > 0 ? ", " : ""}
                      <a href={`/?category=${cat}`} className="category-link">
                        {cat}
                      </a>
                    </>);
                  })
                }
              </Col>
              <Col sm={6} className="breaking">
                {item.pinned && "BREAKING"}
                {
                  !workflowAvailable && <div className="pending pending-workflow">
                    {workflow}
                  </div>
                }
                {
                  pendingLater && <div className="pending pending-later">
                    (live on <Moment date={publishDate} format="ddd D MMM YYYY"/>)
                  </div>
                }
                {
                  pendingSoon && <div className="pending pending-soon">
                    (live at <Moment date={publishDate} format="h:mm a"/>)
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </div>
        <div className="info title">
          <Dotdotdot clamp={1}>
            {item.title}
          </Dotdotdot>
        </div>
        <div className="info description">
          <Dotdotdot clamp={3}>
            {item.desc}
          </Dotdotdot>
        </div>
        <div className="info duration">Duration: <Duration ms={item.duration}/></div>
      </Card.Body>
      <Card.Footer>
        <IButton icon="info" href={href} />
        <IButton
          icon="arrow-down"
          href={`${href}.`}
          variant="warning"
          disabled={!isUser(userInfo)}
        />
      </Card.Footer>
    </Card>
  );
}

function Detail({card,loadCard,onHide,...props}){
  console.log('Details card=',card,' loadCard=',loadCard);
  return(
    <XModal className="FileModal" onHide={onHide}>
      <File initialState={card} loadState={loadCard} {...props}/>
    </XModal>
  );
}

function NoItems({isLoading, ...props}){
  return(
    <div className="NoItems">
      {
        isLoading
          ? <BarsRotate/>
          : "No results"
      }
    </div>
  )
}

export default function ItemList({
  items,
  userInfo,
  isLoading,
  isDone,
  loadMore,
  doReload,
   ...props
 }){
  const [detail,setDetail] = useState(null);
  const [hashLoad,setHashLoad] = useState(null);
  const [view,setView] = useState('details');
  const history = useHistory();

  let location = useLocation();
  useEffect(() => {
    let matchedItem;
    let hash = location.hash;
    if( hash ){
      hash = hash.substr(1);
      const dotIndex = hash.indexOf('.');
      if( dotIndex > 0 ){
        setView('download');
        hash = hash.substring( 0, dotIndex );
      } else {
        setView('details');
      }
      items && items.forEach( item => {
        if( hash === item.id || hash === ""+item.id ){
          matchedItem = item;
        }
      });
      if( matchedItem ){
        setDetail( matchedItem );
        setHashLoad( null );
      } else {
        setDetail( null );
        setHashLoad( hash );
      }
    } else {
      ReactGA.pageview(location.pathname+location.search);
    }
  },[location]);

  function onHide( e ){
    setHashLoad( null );
    setDetail( null );
    history.replace({
      ...location,
      hash: ""
    });
    if( doReload && typeof doReload === 'function' ){
      doReload();
    }
  }

  let cards = items && items.map( item =>
    <ItemCard item={item} key={item.id} userInfo={userInfo} {...props} />
  );

  return (
    <>
      {
        items
          ? <CardDeck className="ItemList" key="ItemList">
              <InfiniteScroll isLoading={isLoading} loadMore={loadMore} isDone={isDone}>
                {items && cards}
              </InfiniteScroll>
            </CardDeck>
          : <NoItems isLoading={isLoading}/>

      }
      {console.log('detail=',detail,' hashLoad=',hashLoad)}
      {
        view === "details" &&
        (detail || hashLoad) &&
        <Detail card={detail} loadCard={hashLoad} onHide={onHide} userInfo={userInfo} {...props}/>
      }
      {
        view === "download" &&
        (detail) &&
        <Download userInfo={userInfo} file={detail} onHide={onHide}/>
      }
    </>
  );
}