const conf = {
  cognito: {
    region:         process.env.REACT_APP_COGNITO_REGION,
    userPoolId:     process.env.REACT_APP_COGNITO_USER_POOL_ID,
    appClientId:    process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  s3: {
    region: process.env.REACT_APP_S3_REGION,
    bucket: process.env.REACT_APP_S3_BUCKET
  },
  api: {
    region: process.env.REACT_APP_API_REGION,
    url:    process.env.REACT_APP_API_URL
  },
  ga: {
    id: process.env.REACT_APP_GA_ID
  }
};
console.log('conf',conf);
export default conf;