import React from "react";
import {useParams} from "react-router-dom";

import LogList from "../../components/LogList";

export default function UserLog({...props}){
  const {username} = useParams();
  return(
    <>
      <LogList title={`Activity Log: ${username}`} username={username}/>
    </>
  )
}