import React from "react";
import "./NotFound.css";
import TextCard from "../components/TextCard";

export default function NotFound() {
  return (
    <TextCard wide className="NotFound">
      <h3>Sorry, page not found!</h3>
    </TextCard>
  );
}