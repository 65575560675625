import React, {useContext, useState, useEffect, useCallback} from "react";
import {Form, Button, InputGroup, Row, Card} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import { Storage } from "aws-amplify";
import moment from "moment";

import LoaderButton from "../../components/LoaderButton";
import TextCard from "../../components/TextCard";
import {Title} from "../../components/Title";
import {UploadFile} from "../../components/UploadFile";
import ButtonRow from "../../components/ButtonRow";

import "./Logo.css";

function compare( f1, f2 ){
  if( (!f1.lastModified && !f2.lastModified) || (f1.lastModified === f2.lastModified) ){
    return 0;
  } else if( !f1.lastModified || (f1.lastModified > f2.lastModified) ){
    return -1;
  } else if( !f2.lastModified || (f1.lastModified < f2.lastModified) ){
    return 1;
  }
}

export async function latestInfo(){
  const files = await Storage.list('logo/',{level:'private'});
  files.sort(compare);
  console.log('files',files);
  if( files && files.length ){
    let file = files[0];
    const url = await Storage.get(file.key,{level:'private'});
    const ret = {
      url,
      ...file
    }
    return ret;
  } else {
    return {};
  }
}

export async function latest(){
  const info = await latestInfo();
  console.log( 'latest', info );
  return info.url || '';
}

function Editor({...props}){
  const [logoUrl, setLogoUrl] = useState( null );
  const [filename, setFilename] = useState( '' );
  const [isLoading, setIsLoading] = useState( false );
  const [uploadProgress, setUploadProgress] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(0);

  const params = new URLSearchParams(window.location.search);
  console.log( 'Editor', window.location.search, params );
  const redirectUrl = params.get('redirectUrl') || '/';
  const reason = params.get('reason');

  useEffect(()=>{

    async function getLatest(){
      setLogoUrl( await latest() );
    }

    getLatest();
  },[lastUpdated]);

  async function handleFileChange(event){
    const file = event.target.files[0];
    const filename = file.name;
    setFilename( filename );

    const now = moment().toISOString();
    const storageName = `logo/${now}.png`;

    setIsLoading( true );
    const stored = await Storage.vault.put( storageName, file, {
      contentType: file.type,
      level: 'private',
      progressCallback: progress => setUploadProgress( 100 * progress.loaded / progress.total )
    });
    setIsLoading( false );

    console.log("stored",stored);
    setFilename( storageName );
    setLastUpdated( Date.now() );
  }

  async function handleCancel(event){
    if( filename && filename.length > 0 ){
      setIsLoading( true );
      await Storage.vault.remove( filename, {level:'private'} );
      setFilename( '' );
      setLastUpdated( 0 );
      setIsLoading( false );
    }
    // Cancelling should always go back home (since the redirectUrl is expecting
    // the logo to be set).
    window.location.assign( '/' );
  }

  return(
    <TextCard narrow className="Logo">
      <Card>
        <Card.Header>
          {logoUrl ? <img src={logoUrl}/> : "No logo set"}
        </Card.Header>
        <Card.Body>
          {
            (reason === "download") &&
            <div className="download">
              You need to set a custom logo before creating a video with that logo.
              Once you have set a logo, you can download customized videos.
            </div>
          }
          <div className="sizing">
            Logos should be a maximum of 320 pixels in either dimension.
          </div>
          <UploadFile
            progress={uploadProgress}
            accept=".png"
            handleFileChange={handleFileChange}
            />
          <ButtonRow>
            <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            <LoaderButton type="submit" isLoading={isLoading} disabled={!lastUpdated} href={redirectUrl}>
              OK
            </LoaderButton>
          </ButtonRow>
        </Card.Body>
      </Card>
    </TextCard>
  );
}

export default function Logo({...props}){
  return (
    <>
      <Title>Custom Logo</Title>
      <Editor {...props}/>
    </>
  );
}