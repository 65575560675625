import React, {useContext, useEffect, useState} from "react";
import TextCard, {Entry} from "../components/TextCard";
import {ApiContext} from "../components/ApiContext";

export default function About(props){
  const [serverVersion,setServerVersion] = useState(null);
  const api = useContext( ApiContext );

  useEffect(() => {

    async function load(){
      const result = await api.get( "media", "/ping" );
      console.log("About",result);
      if( result && result.version ){
        setServerVersion( result.version );
      }
    }

    load();
  });

  const userInfo = (props && props.userInfo) || {};
  console.log(props.userInfo);

  return(
    <TextCard title="About">
      <Entry name="Client">{process.env.REACT_APP_BUILD}</Entry>
      <Entry name="Server" className="server">
        {serverVersion && <span className="version">{serverVersion}</span>}
        {!serverVersion && <span className="no-version">loading</span>}
      </Entry>
      <Entry name="User Name" className="username">
        {userInfo.username}
      </Entry>
      <Entry name="User ID" className="userid">
        {userInfo.sub}
      </Entry>
    </TextCard>
  )
}