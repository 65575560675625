import React from "react";
import {Col, Row} from "react-bootstrap";

import "./ButtonRow.css";

export default function ButtonRow({className="", ...props}){
  return(
    <Row className={`ButtonRow ${className}`}>
      <Col>
        {props.children}
      </Col>
    </Row>
  )
}