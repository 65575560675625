import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import AppliedRoute from "../../components/AppliedRoute";
import {isEditor} from "../../components/Auth";
import NotFound from "../NotFound";
import {NewFile,EditFile} from "./FileEdit";
import {DeleteFile} from "./FileDel";
import FileLog from "./FileLog";

export default function Routes(props){
  let { path } = useRouteMatch();

  function EditorRoutes(){
    return(
      <div className="Editor">
        <Switch>
          <AppliedRoute path={`${path}/file/new`} exact component={NewFile}  appProps={props} />
          <AppliedRoute path={`${path}/file/:id`} exact component={EditFile} appProps={props} />
          <AppliedRoute path={`${path}/delete/:id`} exact component={DeleteFile} appProps={props} />
          <AppliedRoute path={`${path}/log/file/:target`} exact component={FileLog} appProps={props} />
        </Switch>
      </div>
    );
  }

  return(
    isEditor( props.userInfo )
      ? <EditorRoutes/>
      : <NotFound {...props}/>
  );
}