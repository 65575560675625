import React, {useContext, useEffect, useState} from "react";

import "./UserList.css";
import {Card, Navbar} from "react-bootstrap";
import {ApiContext} from "../../components/ApiContext";
import WaitTill from "../../components/WaitTill";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {TableSearch} from "../../components/TableSearch";
import {TableExportButton} from "../../components/TableExportButton";
import {Title} from "../../components/Title";

export default function UserList( props ){
  const api = useContext( ApiContext );

  const [users, setUsers] = useState([]);

  useEffect( () => {

    function loadUsers(){
      return api.get("media", "/admin/user/list", {});
    }

    async function onLoad(){
      try {
        const users = await loadUsers();
        setUsers( users.users );
      } catch( e ){
        console.error( e );
      }
    }

    onLoad()
  }, [api] );

  function idFormat( cell, row, rowIndex, extra ){
    return(
      <a href={`/admin/user/${cell}`}>
        {cell}
      </a>
    )
  }

  const columns = [
    {
      dataField: "username",
      text: "ID",
      sort: true,
      formatter: idFormat
    },
    {
      dataField: "attributes.name",
      text: "Name",
      sort: true
    },
    {
      dataField: "attributes.email",
      text: "Email",
      sort: true
    }
  ];

  return(
    <div className="UserList">
      <Title>Users</Title>
      <ToolkitProvider bootstrap4 data={users} keyField="username" columns={columns} exportCSV search>
        {
          props =>
            <Card>
              <Card.Header>
                <Navbar className="justify-content-between">
                  <Button href="/admin/user/new">New</Button>
                  <TableSearch {...props.searchProps} />
                  <TableExportButton {...props.csvProps}/>
                </Navbar>
              </Card.Header>
              <Card.Body>
                <WaitTill condition={users && users.length} fillColor="#000000">
                  <div className="container">
                    {users &&
                      <BootstrapTable { ...props.baseProps } />
                    }
                  </div>
                </WaitTill>
              </Card.Body>
            </Card>
        }
      </ToolkitProvider>
    </div>
  )
}