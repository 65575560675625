import React, {useContext, useState, useEffect} from "react";
import {useParams} from "react-router-dom";

import LoaderButton from "../../components/LoaderButton";
import {ApiContext} from "../../components/ApiContext";
import WaitTill from "../../components/WaitTill";
import {Button} from "react-bootstrap";

import ButtonRow from "../../components/ButtonRow";
import TextCard, {Entry} from "../../components/TextCard";

import "./FileDel.css";
import {Title} from "../../components/Title";

function PromptDelete({item,...props}){
  const [loading,setLoading] = useState(false);
  const api = useContext(ApiContext);

  async function doDelete(){
    let init = {
      queryStringParameters: {
        id: item.id
      }
    };
    let response = await api.del( "media", "/file/editor", init );
    console.log( "Deleted", response );
    return response;
  }

  async function handleDelete(){
    setLoading(true);
    let response = await doDelete();
    setLoading(false);
    if( response ){
      props.history.push( `/#${item.id}` );
    }
  }

  return(
    <TextCard narrow className={DeleteFile}>
      <Entry name="Title">{item.title}</Entry>
      <Entry name="Description">{item.desc}</Entry>
      <ButtonRow>
        <Button variant="outline-secondary" href={`/#${item.id}`}>
          No, cancel
        </Button>
        <LoaderButton variant="danger" isLoading={loading} onClick={handleDelete}>
          Yes, delete
        </LoaderButton>
      </ButtonRow>
    </TextCard>
  )
}

export function DeleteFile({...props}){
  let {id} = useParams();

  const [state, setState] = useState({});

  const api = useContext( ApiContext );

  useEffect(() => {
    async function loadFile(){
      let params = {
        queryStringParameters: {
          id
        }
      };
      const domain = props.userInfo.format;
      const file = await api.get( "media", `/file/${domain}`, params );
      setState( file );
    }
    loadFile();
  },[api,id]);

  return (
    <>
      <Title>Delete File?</Title>
      <WaitTill condition={state && state.id}>
        {state && state.id && <PromptDelete item={state} {...props}/>}
      </WaitTill>
    </>
  );

}