import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import AppliedRoute from "../../components/AppliedRoute";
import {isUser} from "../../components/Auth";
import NotFound from "../NotFound";
import Logo from "./Logo";
import Download from "./Download";

export default function Routes(props){
  let { path } = useRouteMatch();

  function UserRoutes(){
    return(
      <div className="User">
        <Switch>
          <AppliedRoute path={`${path}/logo`} exact component={Logo}  appProps={props} />
        </Switch>
      </div>
    );
  }

  return(
    isUser( props.userInfo )
      ? <UserRoutes/>
      : <NotFound {...props}/>
  );
}