import React, {useEffect} from "react";
import LoaderButton from "./LoaderButton";

import './InfiniteScroll.css';

export default function InfiniteScroll({
  autoLoad=false, // Should we automatically load (true), or put up a button (false)?
  isLoading,
  isDone,
  loadMore,
  triggerDistance=10,
  ...props
  }){

  useEffect(() => {

    function onScroll( e ){

      if( !autoLoad || isLoading || isDone ){
        return;
      }

      const distance = window.innerHeight + document.documentElement.scrollTop;
      const bottom = document.documentElement.offsetHeight;
      if( distance + triggerDistance > bottom ){
        loadMore();
      }
    }

    window.addEventListener( "scroll", onScroll );

    return () => {
      window.removeEventListener( "scroll", onScroll );
    }
  });

  const showButton = !autoLoad && !isDone;

  return(
    <>
      {props.children || null}
      {showButton && <div className="buttonContainer"><LoaderButton variant="secondary" className="btn-load-more" isLoading={isLoading} onClick={e=>loadMore()}>Load More</LoaderButton></div>}
    </>
  )
}