import React from "react";
import DocumentTitle from "react-document-title";

export function Title({...props}){
  let fullTitle = 'ABC Power Portal';
  let title = props.children;
  if( title ){
    fullTitle = `${fullTitle}: ${title}`
  }

  return(
    <>
      <DocumentTitle title={fullTitle}/>
      <h1>{title}</h1>
    </>
  )
}