import React from "react";

import TextCard from "../components/TextCard";

import "./Contact.css";

function A({block=false,type,...props}){
  let className=type;
  return(
    <div className={className}>
      <a href={`${type}:${props.children}`}>
        {props.children}
      </a>
    </div>
  )
}

function Tel({...props}){
  return(
    <A type="tel" {...props}>
      {props.children}
    </A>
  )
}

function Email({...props}){
  return(
    <a href={`mailto:${props.children}?subject=Interested+In+ABC+Power+Portal`}>
      {props.children}
    </a>
  )
}

function NameBlock({name,tel,email,...props}){
  return(
    <p className="name-block">
      <div className="name">{name}</div>
      <Tel>{tel}</Tel>
      <Email>{email}</Email>
    </p>
  )
}

export default function Contact(props){
  return(
    <TextCard wide className="Contact" title="Contact Us">

      <h3>Affiliate Solutions</h3>

      <NameBlock
        name="Chris Venice"
        tel="212-456-5339"
        email="Christopher.Venice@abc.com" />

      <NameBlock
        name="Heidi Oringer"
        tel="212-456-5541"
        email="Heidi.B.Oringer@abc.com" />

      <h3>ABC Audio</h3>

      <p>
        ABC Power Portal: <Email>ABC.Power.Portal@abc.com</Email>
      </p>

      <p>
        General Contact: <Email>ABC.Audio@abc.com</Email>
      </p>

    </TextCard>
  )
}