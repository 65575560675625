import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Navbar} from "react-bootstrap";

import {Title} from "../../components/Title";
import {ApiContext} from "../../components/ApiContext";

import {Storage} from 'aws-amplify';
import moment from "moment";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import DatePicker from "react-datepicker/es";
import LoaderButton from "../../components/LoaderButton";
import {
  camelToHuman, getTypeInfo,
  longDateTimeFormat,
  ReportConfig,
  ReportTypeSelect, shortDateFormat
} from "./ReportConfig";

import "./Reports.css";

export default function Reports({...appProps}){
  const api = useContext( ApiContext );

  const [processing, setProcessing] = useState(false);
  const [reportType, setReportType] = useState("");
  const [typeInfo,   setTypeInfo] = useState(null );
  const [body, setBody] = useState({
    startDate: moment().startOf("month").toDate(),
    endDate:   moment().endOf("month").toDate()
  })

  useEffect(()=>{
    setTypeInfo( getTypeInfo(reportType) );
  }, [reportType]);

  const [files, setFiles] = useState([]);

  async function loadFiles(){
    const files = await Storage.list( 'report/', {level:'private'} );
    files.forEach( f => {
      const lastSlash = f.key.lastIndexOf('/');

      let name = f.key.substr( lastSlash+1 );
      const startedIndex = name.indexOf('-started');
      if( startedIndex > -1 ){
        f.available = false;
        name = name.substr( 0, startedIndex );
      } else {
        f.available = true;
      }

      const namePart = name.split('-');
      f.reportType = namePart[0];
      const typeInfo = getTypeInfo( f.reportType )
      f.started = Number.parseInt(namePart[1]);
      typeInfo.extractMeta( f, namePart );
    })
    files.sort( (d1, d2) => d2.started - d1.started );
    setFiles( files );
    return files;
  }

  useEffect( () => {
    const intervalHandler = setInterval( loadFiles, 15000 );
    loadFiles();
    return function cleanup(){
      intervalHandler && clearInterval( intervalHandler );
    }
  }, [] );

  async function runReport(e){
    setProcessing( true );
    console.log('runReport');
    const typeInfo = getTypeInfo( reportType );
    const meta = typeInfo.buildMeta({data:body});
    let response = await api.post('media',`/admin/report/${reportType}`,{
      queryStringParameters: {
        typeMeta: meta
      },
      body
    });
    console.log('runReport response',response);
    await loadFiles();
    setReportType("");
    setProcessing( false );
  }

  function reportTypeFormatter( reportType, row, rowIndex, extra ){
    const typeInfo = getTypeInfo( reportType );
    return typeInfo.typeName( row );
  }

  function viewAvailable( available, row, rowIndex, extra ){
    return available
      ? <a href={row.key}>Available</a>
      : "In Progress";
  }

  const columns = [
    {
      dataField: "reportType",
      text: "Report Type",
      sort: true,
      style: {textTransform: "capitalize"},
      formatter: reportTypeFormatter
    },
    {
      dataField: "startTime",
      text: "From",
      sort: true,
      formatter: shortDateFormat
    },
    {
      dataField: "endTime",
      text: "To",
      sort: true,
      formatter: shortDateFormat
    },
    {
      dataField: "started",
      text: "Date Generated",
      sort: true,
      formatter: longDateTimeFormat
    },
    {
      dataField: "available",
      text: "Available",
      formatter: viewAvailable
    }
  ];

  return(
    <div className="Reports">
      <Title>Reports</Title>
      <ToolkitProvider bootstrap4 data={files} columns={columns} keyField="key">
        {
          props =>
            <Card>
              <Card.Header>
                <Navbar className="justify-content-between">
                  <ReportTypeSelect value={reportType} onChange={setReportType} {...appProps}/>
                  <ReportConfig reportType={reportType} data={body} onUpdate={setBody} {...appProps}/>
                  <LoaderButton
                    onClick={runReport}
                    disabled={!reportType || !typeInfo || !typeInfo.isValid({data:body, ...props})}
                    isLoading={processing}
                  >
                    Run Report
                  </LoaderButton>
                </Navbar>
              </Card.Header>
              <Card.Body>
                <BootstrapTable {...props.baseProps}/>
              </Card.Body>
            </Card>
        }
      </ToolkitProvider>
    </div>
  )
}