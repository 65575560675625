import React from "react";
import {Button} from "react-bootstrap";

export function TableExportButton(props){
  function handleClick(){
    props.onExport();
  }

  return (
    <div className="TableExportButton">
      <Button onClick={handleClick}>Export</Button>
    </div>
  );
}

