import React, { useState } from "react";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";

import "./Admin.css";

export default function Admin( props ){

  const [isLoading, setIsLoading] = useState(false);

  async function getEndpoint(){
    return API.get( "media", "/admin/mediaEndpoint" );
  }

  async function doEndpoint( event ){
    console.log('doEndpoint');
    event.preventDefault();
    setIsLoading(true);

    try {
      let result = await getEndpoint();
      console.log(result);
      alert( result );
    } catch( err ){
      console.error( err );
      alert( err );
    }

    setIsLoading(false);
  }

  return (
    <div className="Admin">
      <div id="result"></div>
      <LoaderButton
        block
        isLoading={isLoading}
        onClick={doEndpoint}
      >
        Request MediaConvert Endpoint
      </LoaderButton>
    </div>
  );
}