import React, {useCallback, useContext, useEffect, useState} from "react";

import {Card, Container, Row, Col} from "react-bootstrap";
import {Auth, Storage} from "aws-amplify";

import {Title} from "../../components/Title";
import {ApiContext} from "../../components/ApiContext";
import {latest, latestInfo} from "./Logo";
import BarsRotate from "../../components/spinner/BarsRotate";

import "./Download.css";
import {XModal} from "../../components/XModal";

export default function Download({userInfo, file, onHide, ...props}){
  const {id, filename} = file;
  console.log('Download',id,filename);

  const logoMaxRatio = (1/6);
  const border = 20;                 // Number of pixels around logo
  const opacity = 100;

  const [msgState,setMsgStateRaw] = useState('pending');
  const api = useContext( ApiContext );
  const format = userInfo.format;

  const [displayPad,setDisplayPad] = useState(0);
  const [buttonInfo, setButtonInfo] = useState({});
  const [imgInfo, setImgInfo] = useState({});
  const [thumbnailScale, setThumbnailScale] = useState(0);

  const [logo,setLogo] = useState( null );
  const [url,setUrl] = useState( null );
  const [urlUpdated,setUrlUpdated] = useState( null );

  const formatCategoryParams = `&userFormat=${window.localStorage.getItem('format')}&userCategory=${window.localStorage.getItem('category')}`;

  function setMsgState( val ){
    console.log('message state:',val);
    setMsgStateRaw( val );
  }

  // When we are loaded, get the generated URL (if we have it), logo URL,
  // and anything else we need up front.
  useEffect(()=>{

    async function imageDimensions( url ){
      return new Promise( (resolve,reject) => {
        let image = new Image();
        image.onload = () => {
          resolve({width:image.width,height:image.height})
        }
        image.src = url;
      });
    }

    function scaledDimensions( maxWidth, maxHeight, width, height ){
      const scale = width > height ?
        maxWidth / width :
        maxHeight / height;
      return {
        width: width*scale,
        height: height*scale
      }
    }

    async function scaledUrl( maxRatio, url ){
      const {width, height} = await imageDimensions( url );
      const maxWidth = file.width * maxRatio;
      const maxHeight = file.height * maxRatio;
      const ret = scaledDimensions( maxWidth, maxHeight, width, height );
      console.log('scaledUrl',{width,height},ret);
      return ret;
    }

    async function loadLogo(){
      const {url,lastModified} = await latestInfo();

      if( !url ){
        setMsgState( 'missingLogo' );
      } else {
        const {width, height} = await scaledUrl( logoMaxRatio, url );
        console.log( "scaledUrl result", width, height );

        setLogo({
          url,
          lastModified,
          width,
          height
        });
      }

      return lastModified;
    }

    async function loadUrl(){
      console.log('loadUrl', filename, url);
      const key = `video/${filename}`;
      const file = await Storage.list(key,{level:'private'});
      if( file && file.length ){
        // We know the file is waiting for us
        // Get a signed URL to display it and set things as available.
        await videoAvailable();

        // Set the updated date
        const lastModified = file[0].lastModified;
        return lastModified;

      } else {
        // Mark things as unavailable
        setMsgState( 'unavailable' );
      }
    }

    async function loadInfo(){
      const urlModified = await loadUrl();
      const logoModified = await loadLogo();   // Load logo second in case it is missing

      console.log({urlModified,logoModified});
      if( urlModified && logoModified && urlModified < logoModified ){
        console.log('logo modified');
        setMsgState( 'unavailable' );
      }
    }

    loadInfo();
  }, [filename]);

  async function videoAvailable(){
    const credentials = await Auth.currentUserCredentials();
    console.log({credentials,userInfo});
    const url = `${process.env.REACT_APP_API_URL}/link/personal`+
      `?page=${id}&personal=${credentials.identityId}&user=${userInfo.userid}`+
      formatCategoryParams;
    setUrl( url );
    setMsgState( 'available' );
    console.log(url);
    //window.location = url;
    return url;
  }

  async function generateVideo(){
    let reloadTimeout = null;
    let generatingFile = false;

    async function loadUrlProcessing(){
      generatingFile = true;
      setUrl('');
      setMsgState('processing');

      const {width, height} = logo;

      // Get the dimensions of the video so we know where to place the logo
      const params = {
        queryStringParameters: {
          id
        }
      };
      const video = await api.get( "media", `/file/${format}`, params );
      console.log( "video", video );
      const videoWidth = video.width;
      const videoHeight = video.height;
      const offsetX = videoWidth - ( border + width );
      const offsetY = border;

      let body = {
        id,
        filename,
        width,
        height,
        offsetX,
        offsetY,
        opacity
      };
      console.log('body',body);
      const  init = {
        body
      };
      api.post( "media", `/file/${format}`, init ).then( response => {
        console.log( "Created", response );
      });
    }

    async function loadUrl(){
      console.log('generateVideo.loadUrl', generatingFile, filename, url);
      const key = `video/${filename}`;
      const file = await Storage.list(key,{level:'private'});
      if( file && file.length && file[0].lastModified > logo.lastModified ){
        // We know the file is waiting for us, get a signed URL to display it
        // and change the message state to indicate it is available.
        // Then begin downloading it.
        const url = await videoAvailable();
        window.location = url;

      } else {
        // If we haven't, start generating the file.
        if( !generatingFile ){
          await loadUrlProcessing();
        }

        // Either way, check again in a few seconds
        reloadTimeout = setTimeout( loadUrl, 5000 );
      }
    }

    await loadUrl();
  }

  const imgRef = useCallback( node => {
    console.log(node);
    if( node && node.getBoundingClientRect().width > 0 ){
      console.log( node.parentElement );
      const div = node.parentElement.parentElement;

      const buttonRect = div.getBoundingClientRect();
      console.log('buttonRect',buttonRect);
      setButtonInfo( buttonRect );

      const imgRect = node.getBoundingClientRect();
      console.log('imgRect',imgRect);
      setImgInfo( imgRect );

      const pad = (buttonRect.width - imgRect.width) / 2;
      console.log('pad',pad);
      setDisplayPad( pad );

      const scale = imgRect.width / file.width;
      setThumbnailScale( scale );
    }
  }, [msgState]);

  function A({...props}){
    switch( msgState ){
      case 'missingLogo': return (<a href={`/user/logo?reason=download&redirectUrl=${encodeURIComponent(window.location)}`}>{props.children}</a>);
      case 'available':   return (<a href={url}>{props.children}</a>);
      case 'unavailable': return (<a onClick={generateVideo}>{props.children}</a>);
      default:            return (<a>{props.children}</a>)
    }
  }

  return (
    <XModal className="DownloadModal" onHide={onHide}>
      <div className={`Download Download-${msgState}`}>
        <Title>Download</Title>
        <Card>
          <Card.Body>
            <Container>
              <Row>
                <Col xs="12" lg="6" className="download-header download-custom">
                  <h2>
                    {msgState === 'processing' && "Generating"} Custom Video
                  </h2>
                </Col>
                <Col xs="0" lg="6" className="download-header download-original">
                  <h2>
                    Original Video
                  </h2>
                </Col>
              </Row>
              <Row>
                {
                  msgState === 'pending' &&
                  <Col xs="12" lg="6" className="download-button download-pending">
                    <div className="spinner spinner-pending">
                      <BarsRotate fillColor="black" />
                    </div>
                  </Col>
                }
                {
                  msgState !== 'pending' &&
                  <Col xs="12" lg="6" className={`download-button download-custom download-${msgState}`}>
                    <A>
                      <img className="thumbnail" src={file.thumbnail} style={{left:displayPad}}/>
                      {
                        // If we have a logo, scale it and position it correctly.
                        // The extra "5" for the top and right are to match the
                        // border in the style sheet.
                        // msgState !== "missingLogo" &&
                        logo && logo.url &&
                        <img
                          className="logo"
                          src={logo.url}
                          style={{
                            top: 5 + border * thumbnailScale,
                            right: 5 + (border * thumbnailScale) + displayPad,
                            width: logo.width * thumbnailScale,
                            height: logo.height * thumbnailScale
                          }}
                        />
                      }
                      {
                        // We don't have a logo, so display a message there instead
                        // msgState === "missingLogo &&
                        !(logo && logo.url) &&
                        <div
                          className="no-logo"
                          style={{
                            top: 5 + border * thumbnailScale,
                            right: 5 + (border * thumbnailScale) + displayPad
                          }}
                          >
                          Add Logo
                        </div>
                      }
                      {
                        // If we are processing, then show a spinner and grey
                        // out the image.
                        msgState === 'processing' &&
                        <>
                          <div className="spinner spinner-processing">
                            <BarsRotate fillColor="white"/>
                          </div>
                          <div className="dim" style={{
                            left: displayPad,
                            height: imgInfo.height,
                            width: imgInfo.width
                          }}/>
                        </>
                      }
                    </A>
                  </Col>
                }
                <Col xs="12" lg="0" className="download-header download-original">
                  <h2>
                    Original Video
                  </h2>
                </Col>
                <Col xs="12" lg="6" className="download-button download-original">
                  <a href={`${file.file}${formatCategoryParams}`}>
                    <img className="thumbnail" src={file.thumbnail} style={{left:displayPad}} ref={imgRef}/>
                  </a>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </XModal>
  )
}