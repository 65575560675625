import React from "react";
import {useParams} from "react-router-dom";

import LogList from "../../components/LogList";

export default function FileLog({...props}){
  const {target} = useParams();
  return(
    <>
      <LogList title={`File Log`} target={target}/>
    </>
  )
}