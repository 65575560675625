import React from "react";
import BarsRotate from "./spinner/BarsRotate";

export default function WaitTill({
  condition,
  fillColor,
  ...props
}){
  return(
    <>
      {condition
        ? props.children
        : <div className="WaitTill">
            <BarsRotate fillColor={fillColor}/>
          </div>
      }
     </>
  )
}
