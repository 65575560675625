import React from "react";
import {Container,Row,Col} from "react-bootstrap";

import "./TextCard.css";
import {Title} from "./Title";

export function Entry({name, className="", classNameName="", classNameContents="",...props}){
  return(
    <Row className={`Entry ${className}`}>
      <Col sm={3} className={`entry-name ${classNameName}`}>
        {name}
      </Col>
      <Col sm={9} className={`entry-contents ${classNameContents}`}>
        {props.children}
      </Col>
    </Row>
  )
}

export default function TextCard({wide=false,narrow=true,thin=false,className="",title,...props}){
  let sm;
  if( !sm && wide ){
    sm = {span:12}
  }
  if( !sm && narrow ){
    sm = {span:8, offset:2}
  }
  if( !sm && thin ){
    sm = {span:6, offset:3}
  }

  return(
    <>
      {title && <Title>{title}</Title>}
      <Row>
        <Col sm={sm}>
          <Container className={`TextCard ${className}`}>
            {props.children}
          </Container>
        </Col>
      </Row>
    </>
  )
}