import React, {useContext, useState, useEffect, useCallback} from "react";
import {Form} from "react-bootstrap";
import {Line} from "rc-progress";

export function UploadFile({ progress, name, accept, handleFileChange, ...props}){
  return (
    <div className="Upload">
      {
        (progress === 0) &&
        <Form.Control type="file" accept={accept} onChange={e => handleFileChange(e)}/>
      }
      {
        progress > 0 && progress < 100 &&
        <>
          Uploading <b>{name}</b>
          <Line percent={progress}/>
        </>
      }
      {
        (progress === 100) &&
        <>
          Uploaded <b>{name}</b>
        </>
      }

    </div>
  )
}