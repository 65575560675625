import React, {useEffect} from 'react';

function Squares(props) {
  const title = props.title || "squares";

  useEffect(() => {

    let elements = document.getElementsByClassName( "nc-loop_squares-48" );
    let element = elements[0];
    let rect = [
      element.getElementsByTagName( "rect" )[2],
      element.getElementsByTagName( "rect" )[1],
      element.getElementsByTagName( "rect" )[0],
      element.getElementsByTagName( "rect" )[3]
    ];
    let start = null;

    function t( t, e ){
      for( let n in e ) t.setAttribute( n, e[n] )
    }

    function animate( e ){
      start || (start = e);
      let i = e - start, a = Math.min( i / 50, 24.5 );
      1225 > i || (start = start + 1225);
      let s = [], o = [], m = [], c = [];
      for( let r = 0; 4 > r; r++ ){
        let h = 1 + .15 * r;
        s[r] = Math.min( Math.abs( h - a / 10 ), 1 );
        o[r] = 24 * (1 - s[r]);
        m[r] = 24 * (1 - s[r]);
        c[r] = .6 * s[r] + .4;
        t( rect[r], {
          transform: "translate(" + o[r] + " " + m[r] + ") scale(" + s[r] + ")",
          style: "opacity:" + c[r] + ";"
        } )
      }

      animationId = requestAnimationFrame( animate );
    }

    let animationId = requestAnimationFrame( animate );

    return(() =>{
      if( animationId ){
        cancelAnimationFrame( animationId );
      }
    });
  }, []);

  return (
    <svg height="48" width="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill="#ffb236">
        <g className="nc-loop_squares-48">
          <rect height="24" style={{"opacity":"0.9292048000097275"}} width="24" fill="#ffb236" transform="translate(2.8318079996109002 2.8318079996109002) scale(0.8820080000162125)"/>
          <rect height="24" style={{"opacity":"1"}} width="24" fill="#ffb236" transform="translate(0 0) scale(1)" x="24" y="24"/>
          <rect height="24" style={{"opacity":"1"}} width="24" transform="translate(0 0) scale(1)" x="24"/>
          <rect height="24" style={{"opacity":"0.8392048000097276"}} width="24" transform="translate(6.431807999610898 6.431807999610898) scale(0.7320080000162126)" y="24"/>
        </g>
      </g>
    </svg>
  );
};

export default Squares;