import React, {useContext, useState, useEffect, useCallback} from "react";
import {useParams} from "react-router-dom";
import {Form, Button, InputGroup, Row, FormCheck} from "react-bootstrap";
import { Storage } from "aws-amplify";
import { Line } from "rc-progress";

import LoaderButton from "../../components/LoaderButton";
import TagInput from "../../components/TagInput";
import {ApiContext} from "../../components/ApiContext";
import WaitTill from "../../components/WaitTill";

import ButtonRow from "../../components/ButtonRow";
import TextCard, {Entry} from "../../components/TextCard";

import moment from "moment";
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";

import {Title} from "../../components/Title";

import "./FileEdit.css";
import {formatTitles, validFormat} from "../../App";
import {UploadFile} from "../../components/UploadFile";

function FormatEntry({ format, categories, value, onChange, ...props}){
  value = cleanValue( value );
  console.log("FormatEntry",format,value,categories);

  function cleanValue( value ){
    // Must be an array
    if( !Array.isArray(value) ){
      value = [value];
    }

    // Shouldn't have "none"...
    value = value.reduce( (previousValue, currentValue) => {
      if( currentValue !== "none" ){
        previousValue.push( currentValue );
      }
      return previousValue;
    }, [] );

    // ...unless it is the only one
    if( value.length === 0 ){
      value = ["none"]
    }

    return value;
  }

  function addValue( cat ){
    value.push( cat );
    return cleanValue(value);
  }

  function removeValue( cat ){
    const newValue = [];
    value.forEach( val => {
      if( cat !== val ){
        newValue.push( val );
      }
    })
    return cleanValue(newValue);
  }

  function toggle( cat, e ){
    console.log(cat, e);
    const checked = e.target.checked;
    const newValue = checked ? addValue( cat ) : removeValue( cat );
    console.log( newValue );
    onChange( newValue );
  }

  return(
    <Row className="FormatEntry">
      <InputGroup>
        <InputGroup.Text>{formatTitles[format] || format}</InputGroup.Text>
        <InputGroup.Text className="categories">
          <div>
            {categories.map( category => {
              const id = `FormatEntry ${format} ${category}`.replaceAll(/[^a-z0-9]/ig,'-');
              return(
                <FormCheck
                  key={id}
                  id={id}
                  label={category}
                  checked={value.indexOf( category ) >= 0}
                  onChange={e => toggle(category,e)}
                />
              )
            })}
          </div>
        </InputGroup.Text>
        {/*
        <Form.Control
          as="select"
          className="custom-select"
          onChange={onChange}
          value={value}
        >
          <option></option>
          {categories.map( category => <option key={category}>{category}</option> )}
        </Form.Control>
        */}
      </InputGroup>
    </Row>
  )
}

const defaultItem = {
  id: 0,
  title: "",
  desc: "",
  category: null,
  tags: []
};

function defaultCategory( categories ){
  let ret = {};
  Object.keys( categories )
    .filter(validFormat)
    .forEach( key => {
    ret[key] = "";
  });
  return ret;
}

function NowPicker({ selected, onChange, ...props }){
  const [when, setWhen] = useState("now");
  const [date, setDate] = useState( selected );

  function changeWhen( e ){
    const val = e.target.value;
    setWhen( val );
    if( val === 'now' ){
      changeDate( new Date() );
    }
  }

  function changeDate( val ){
    onChange( val );
    setDate( val );
  }

  return (
    <div className="NowPicker">
      <Form.Control as="select" onChange={changeWhen} value={when}>
        <option value="now">Now</option>
        <option value="at">At</option>
      </Form.Control>
      {
        when === 'at' &&
        <DatePicker selected={date} onChange={changeDate} {...props}/>
      }
    </div>
  )
}

function Upload({progress, name, fileType, file, handleFileChange, ...props}){
  console.log('handleFileChange',handleFileChange)
  return (
    <Entry name={name}>
      <UploadFile
        progress={progress}
        name={file[fileType] && file[fileType].name}
        handleFileChange={e => handleFileChange(e, fileType)}
        {...props}
        />
    </Entry>
  );
}

function baseUrl( url ){
  if( !url ){
    return url;
  }

  const qmark = url.indexOf('?');
  if( qmark === -1 ){
    return url;
  }

  return url.substring( 0, qmark );
}

function Thumb({isUpload, thumbnail, onClick, isSelected, ...props}){
  return (
    <div className={`Thumb col-sm-3 ${isSelected ? 'selected' : 'notSelected'}`}>
      {
        thumbnail &&
        <img className="img" src={thumbnail} onClick={onClick}/>
      }
      {
        !thumbnail && isUpload &&
          <div className="img noThumbnail" onClick={onClick}>
            Upload Image
          </div>
      }
    </div>
  )
}

function ThumbEntry({thumbnails, selected, setIndex, ...props}){
  return (
    <div className="ThumbEntry row">
      {
        [0,1,2,3].map( co => {
          return (
            (thumbnails[co] || co<=1) &&
            <Thumb
              key={"thumb"+co}
              isUpload={co === 0}
              thumbnail={thumbnails && thumbnails[co]}
              isSelected={co === selected}
              onClick={e => setIndex(co)}
            />
          )
        } )
      }
    </div>
  )
}

function Editor({item=defaultItem, categories, ...props}) {
  const api = useContext( ApiContext );

  const isNew = item.id === 0;

  const [file, setFile] = useState( {});
  const [title, setTitle] = useState(item.title);
  const [desc, setDesc] = useState(item.desc);
  const [category, setCategory] = useState(item.category || defaultCategory(categories));
  const [hasFormat, setHasFormat] = useState( false );
  const [tags, setTags] = useState(item.tags);
  const [pinned, setPinned] = useState( item.pinned || false );
  const [workflow, setWorkflow] = useState(item.workflow);
  console.log('Editor workflow', item.workflow, workflow);

  console.log('category',categories,category);

  const [published,setPublished] = useState( item.published || item.created );
  let publishedDate = new Date( moment(published).valueOf() );
  useEffect(()=>{
    publishedDate = new Date( moment(published).valueOf() );
  },[published]);
  const setPublishedDate = useCallback( val => {
    const newPublished = val ? moment(val).utc().toISOString() : '';
    console.log('newPublished',newPublished);
    setPublished( newPublished );
  });

  const [thumbnailIndex, setThumbnailIndex] = useState( 1 );
  useEffect(()=>{
    const thumbnail = baseUrl( item.thumbnail );
    const thumbnails = item.thumbnails && item.thumbnails.map( t => baseUrl( t ) )
    const index = thumbnails && thumbnails.indexOf( thumbnail );
    if( index >= 0 ){
      setThumbnailIndex( index );
    }
  },[]);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [isUpdateFile, setUpdateFile] = useState( false );
  const [isNewOrUpdateFile, setIsNewOrUpdateFile] = useState( false );
  useEffect(()=>setIsNewOrUpdateFile( isNew || isUpdateFile),[isNew,isUpdateFile]);

  function setFileTypeValue( type, value ){
    const newFile = {
      ...file
    }
    newFile[type] = {name:value};
    console.log('setFileTypeValue',type,value,newFile);
    setFile( newFile );
  }

  function validateForm() {
    let nonNull =
      (!isNew || (file && file.video && file.video.name && file.video.name.length > 0)) &&
      title.length > 0 &&
      desc.length > 0 ;
    return nonNull;
  }

  async function handleFileChange(event, type){
    const file = event.target.files[0];
    const filename = file.name;
    setFileTypeValue( "generic", filename );

    setIsLoading( true );
    const stored = await Storage.vault.put( filename, file, {
      contentType: file.type,
      progressCallback: progress => setUploadProgress( 100 * progress.loaded / progress.total )
    });
    setIsLoading( false );

    console.log("stored",stored);
    setFileTypeValue( type, stored.key );
  }

  const updateCategory = useCallback( (format, cat) => {
    let newCategory = {...category};
    newCategory[format] = cat||"none";
    setCategory( newCategory );
  }, [category] );

  useEffect( () => {
    const formatSet = Object.keys( category )
      .map( format => category[format] )
      .reduce( (result, cat) => result || (cat && cat !== 'none'), false );
    console.log('category changed - hasFormat', formatSet);
    setHasFormat( formatSet );
  }, [category] );

  async function submitNew(){
    let body = {
      file,
      title,
      desc,
      category,
      tags,
      pinned,
      published,
      workflow: 'in progress'
    };
    console.log("Creating:",body);
    let init = {
      body
    };
    let response = await api.post( "media", "/file/editor", init );
    console.log( "Created", response );
    return response;
  }

  async function submitEdit(){
    let body = {
      file,
      title,
      desc,
      category,
      tags,
      pinned,
      published,
      thumbnailIndex,
      workflow: workflow || 'available'
    };
    if( !body.tags ){
      body.tags = [];
    }
    console.log("Modifying:",body);
    let init = {
      queryStringParameters: {
        id: item.id
      },
      body
    };
    let response = await api.patch( "media", "/file/editor", init );
    console.log( "Modified", response );
    return response;
  }

  async function submit(){
    return isNew ? await submitNew() : await submitEdit();
  }

  async function handleSubmit(event) {
    event && event.preventDefault();
    setIsLoading(true);
    let response = await submit();
    setIsLoading(false);
    if( response ){
      let id = response.id;
      if( id ){
        props.history.push( `/#${id}` );
      }
    }
  }

  useEffect( async () => {
    console.log('useEffect workflow')
    if( workflow !== item.workflow ){
      await handleSubmit();
    }
  }, [workflow] );

  function WorkflowDisplay(){
    let ret = workflow;
    let badgeColor = 'badge-info';
    if( !ret ){
      ret = isNew ? 'in progress' : 'available';
    }
    if( ret !== 'available' ){
      badgeColor = 'badge-warning';
    }
    if( ret === 'available' && moment(published).isAfter() ){
      ret = 'scheduled';
    }
    return(
      <span className={`WorkflowDisplay badge badge-pill ${badgeColor} text-capitalize`}>{ret}</span>
    );
  }

  function Workflow({next, disabled=false, className="", ...props}){

    async function onClick( e ){
      console.log('workflow onClick');
      setWorkflow( next );
      e.preventDefault();
    }

    console.log('workflow',workflow,next);
    if( workflow !== next ){
      return(
        <Button
          className={`Workflow ${className}`}
          disabled={isLoading || disabled}
          onClick={onClick}
        >
          {props.children}
        </Button>
      )
    }
    return null;
  }

  return (
    <TextCard narrow className="FileEdit">
      <form onSubmit={handleSubmit}>

        <Entry name="Title">
          <Form.Control
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </Entry>

        <Entry name="Description">
          <Form.Control as="textarea"
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
          />
        </Entry>

        <Entry name="Category Mapping" className="formats">
          {
            Object.keys(categories)
              .filter(validFormat)
              .map( format =>
              <FormatEntry
                key={"key-"+format}
                format={format}
                categories={categories[format]}
                value={category[format]}
                onChange={val => updateCategory( format, val )}
              />
            )
          }
        </Entry>

        <Entry name="Tags" className="tags">
          <TagInput tags={tags} setTags={setTags}/>
        </Entry>

        <Entry name="Breaking">
          <Form.Check type="checkbox" onChange={e => setPinned(e.target.checked)} checked={pinned} />
        </Entry>

        {
          !isNew &&
          <Entry name="Published">
            <DatePicker
              dateFormat="EEE d MMM yyyy h:mm:ss a"
              autoFocus={false}
              selected={publishedDate}
              onChange={setPublishedDate}
              showTimeInput timeInputLabel="Time:"
            />
          </Entry>
        }
        {
          isNew &&
            <Entry name="Publish">
              <NowPicker
                dateFormat="EEE d MMM yyyy h:mm:ss a"
                autoFocus={false}
                selected={publishedDate}
                onChange={setPublishedDate}
                showTimeInput timeInputLabel="Time:"
              />
            </Entry>
        }

        <Entry name="Thumbnail">
          {
            isNewOrUpdateFile &&
            <div>
              You can upload/select
              {isUpdateFile ? " a new " : " a "}
              thumbnail after the video has been processed.
            </div>
          }
        </Entry>

        {
          !isNew &&
          <ThumbEntry
            thumbnails={item.thumbnails || [null, item.thumbnail]}
            selected={thumbnailIndex}
            setIndex={setThumbnailIndex}
          />
        }

        {
          !isNewOrUpdateFile &&
          thumbnailIndex === 0 &&
          <Upload
            progress={uploadProgress}
            file={file}
            name="New Thumbnail"
            fileType="thumb"
            handleFileChange={handleFileChange}
          />
        }

        {
          isNewOrUpdateFile &&
          <Upload
            progress={uploadProgress}
            file={file}
            name={isNew ? "File" : "Update File"}
            fileType="video"
            handleFileChange={handleFileChange}
          />
        }
        {
          !isNewOrUpdateFile &&
          <Entry name="File">
            <div>
              <span className="filename">{item.filename}</span>
              {!uploadProgress &&
                <span className="update badge badge-primary text-uppercase">
                  <a onClick={() => setUpdateFile( true )}>Re-upload</a>
                </span>
              }
            </div>
          </Entry>
        }

        <Entry name="Workflow">
          <WorkflowDisplay/>
        </Entry>

        <hr/>

        <ButtonRow>
          <div className="group">
            {
              !isNew &&
              <div>
                <Workflow next="in progress">In Progress</Workflow>
                <Workflow next="awaiting review">Awaiting Review</Workflow>
                <Workflow next="available" disabled={!hasFormat}>
                  {
                    moment(published).isBefore() ? 'Make Available' : 'Schedule'
                  }
                </Workflow>
              </div>
            }
          </div>
          <div className="group">
            <Button variant="outline-secondary" href={`/${isNew ? '' : '#'+item.id}`}>Cancel</Button>
            <LoaderButton type="submit" isLoading={isLoading} disabled={!validateForm()}>
              {isNew ? "Create" : "Update"}
            </LoaderButton>
          </div>
        </ButtonRow>
      </form>
    </TextCard>
  );
}

export function NewFile({categories, ...props}){
  return(
    <>
      <Title>Upload File</Title>
      <Editor categories={categories} {...props}/>
    </>
  );
}

export function EditFile({categories, ...props}){
  let {id} = useParams();

  const [state, setState] = useState({});

  const api = useContext( ApiContext );

  useEffect(() => {
    async function loadFile(){
      let params = {
        queryStringParameters: {
          id
        }
      };
      const domain = props.userInfo.format;
      const file = await api.get( "media", `/file/${domain}`, params );
      console.log('file:',file);
      setState( file );
    }
    loadFile();
  },[api,id]);

  return (
    <>
      <Title>Edit File</Title>
      <WaitTill condition={state && state.id}>
        {state && state.id && <Editor categories={categories} item={state} {...props}/>}
      </WaitTill>
    </>
  );

}