import React, {useState} from "react";
import {API as AAPI} from "aws-amplify";
import Modal from "react-bootstrap/Modal";

import "./ApiContext.css";

export const ApiContext = React.createContext();

export function ApiContextProvider( props ){

  const [error,setError] = useState(null);

  function handleError( xx ){
    console.error("Problem with API Call",JSON.stringify(xx));
    console.error('Response:',xx.response);
    setError( (xx.response && xx.response.data && xx.response.data.errorMessage) || "API Error" );
    return (xx.response && xx.response.data) || {};
  }

  function storage( name ){
    const val = window.localStorage.getItem( name ) || '-';
    const ret = val.toLowerCase().replaceAll( " ", "_" );
    return ret;
  }

  function supplement( init = {} ){
    const headers = init.headers || {};

    const format = storage( "format" );
    const category = storage( "category" );
    headers['Accept'] = `application/json, text/plain, */*, x-format/${format}, x-category/${category}`;

    init.headers = headers;
    return init;
  }

  async function get( apiName, path, init ){
    try{
      return await AAPI.get( apiName, path, supplement(init) );
    } catch( xx ){
      return handleError( xx );
    }
  }

  async function post( apiName, path, init ){
    try{
      return await AAPI.post( apiName, path, supplement(init) );
    } catch( xx ){
      return handleError( xx );
    }
  }

  async function put( apiName, path, init ){
    try{
      return await AAPI.put( apiName, path, supplement(init) );
    } catch( xx ){
      return handleError( xx );
    }
  }

  async function head( apiName, path, init ){
    try{
      return await AAPI.head( apiName, path, supplement(init) );
    } catch( xx ){
      return handleError( xx );
    }
  }

  async function patch( apiName, path, init ){
    try{
      return await AAPI.patch( apiName, path, supplement(init) );
    } catch( xx ){
      return handleError( xx );
    }
  }

  async function del( apiName, path, init ){
    try{
      return await AAPI.del( apiName, path, supplement(init) );
    } catch( xx ){
      return handleError( xx );
    }
  }

  const values = {
    error,
    setError,
    get,
    post,
    put,
    head,
    patch,
    del
  };

  return(
    <ApiContext.Provider value={values}>
      <Modal onHide={()=>setError(null)} show={!!error} className="ApiError">
        <Modal.Header closeButton={true}>
          There has been a problem
        </Modal.Header>
        <Modal.Body>
          <div>
            An unexpected error occurred, please try again.
            If this error continues, report this problem with a summary of what you were trying to do.
          </div>
          <div>
            <a href="/">Return home</a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {error ? error : ""}
        </Modal.Footer>
      </Modal>

      {props.children}
    </ApiContext.Provider>
  )
}
