import React, {useContext, useEffect, useState} from "react";
import {Card, Navbar} from "react-bootstrap";
import WaitTill from "../../components/WaitTill";
import {ApiContext} from "../../components/ApiContext";
import moment from "moment";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {TableExportButton} from "../../components/TableExportButton";
import {TableSearch} from "../../components/TableSearch";

import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";

import "./UserSummary.css";
import {Title} from "../../components/Title";

export default function UserSummary({...props}){
  const api = useContext( ApiContext );

  const [users, setUsers] = useState([]);
  const [activityAfter, setActivityAfter] = useState(moment().startOf("month").toDate());
  const [activityBefore, setActivityBefore] = useState(moment().endOf("month").toDate());

  function setActivityDate({after = activityAfter, before = activityBefore}){
    if( after > before ){
      [after, before] = [before, after];
    }
    setActivityAfter( after );
    setActivityBefore( before );
    setUsers( [] );
  }

  useEffect( () => {

    function loadUsers(){
      let params = {
        queryStringParameters: {
          activityAfter: moment(activityAfter).utc().format(),
          activityBefore: moment(activityBefore).utc().format()
        }
      };
      return api.get("media", "/admin/user/list", params);
    }

    async function onLoad(){
      try {
        const users = await loadUsers();
        console.log( users.users );
        setUsers( users.users );
      } catch( e ){
        console.error( e );
      }
    }

    onLoad()
  }, [activityAfter,activityBefore] );

  function dateFormat( cell, row, rowIndex, extra ){
    if( cell ){
      return moment(cell).local().format("ddd D MMM YYYY hh:mm:ss a");
    } else {
      return "";
    }
  }

  function dateFormatCsv( cell, row, rowIndex, extra ){
    if( cell ){
      return moment(cell).local().format("MM/DD/YYYY HH:mm:ss");
    } else {
      return "";
    }
  }

  const columns=[
    {
      dataField: "username",
      text: "ID",
      sort: true
    },
    {
      dataField: "attributes.name",
      text: "Name",
      sort: true
    },
    {
      dataField: "attributes.email",
      text: "Email",
      hidden: true,
      csvExport: true
    },
    {
      dataField: "lastActivity",
      text: "Last Activity",
      sort: true,
      formatter: dateFormat,
      csvFormatter: dateFormatCsv
    },
    {
      dataField: "countList",
      text: "List",
      sort: true,
      align: "right",
      csvText: "Card List Views",
      csvType: Number
    },
    {
      dataField: "countDetail",
      text: "Detail",
      sort: true,
      align: "right",
      csvText: "Card Detail Views",
      csvType: Number
    },
    {
      dataField: "countPreview",
      text: "Preview",
      sort: true,
      align: "right",
      csvText: "Video Previews",
      csvType: Number
    },
    {
      dataField: "countDownload",
      text: "Download",
      sort: true,
      align: "right",
      csvText: "Video Downloads",
      csvType: Number
    },
    {
      dataField: "countPersonal",
      text: "Custom",
      sort: true,
      align: "right",
      csvText: "Custom Downloads",
      csvType: Number
    }
  ];

  return(
    <div className="UserSummary">
      <Title>Usage Report</Title>
      <ToolkitProvider bootstrap4 data={users} keyField="username" columns={columns} exportCSV search>
        {
          props =>
            <Card>
              <Card.Header>
                <Navbar className="justify-content-between">
                  <div className="dateEntry">
                    <span className="dateEntryLabel">
                      Date Range
                    </span>
                    <DatePicker
                      selected={activityAfter}
                      onChange={date => setActivityDate({after:date})}
                      selectsStart
                      startDate={activityAfter}
                      endDate={activityBefore}
                      className="datePicker datePicker-start"
                    />
                    <span className="to">to</span>
                    <DatePicker
                      selected={activityBefore}
                      onChange={date => setActivityDate({before:date})}
                      selectsEnd
                      startDate={activityAfter}
                      endDate={activityBefore}
                      className="datePicker datePicker-end"
                    />
                  </div>

                  <div>
                    <TableSearch {...props.searchProps} />
                  </div>

                  <TableExportButton {...props.csvProps}/>
                </Navbar>
              </Card.Header>
              <Card.Body>
                <WaitTill condition={users && users.length} fillColor="#000000">
                  {users &&
                  <BootstrapTable { ...props.baseProps } />
                  }
                </WaitTill>
              </Card.Body>
            </Card>
        }
      </ToolkitProvider>
    </div>
  )
}