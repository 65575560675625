/**
 * Is the user in at least one of the groups listed
 * @param userInfo
 * @param groups {string|Array}
 * @return {*|boolean}
 */
function hasGroup( userInfo, groups ){
  if( !userInfo || !userInfo.groups ){
    return false;
  }
  if( typeof groups === "string" ){
    groups = [groups];
  }
  let isMember = groups.map( group => userInfo.groups.indexOf( group ) >= 0 );
  let ret = isMember.reduce( ((previousValue, currentValue) => previousValue || currentValue) );
  return ret;
}

export function isAdmin( userInfo ){
  return hasGroup( userInfo, "admin" );
}

export function isEditor( userInfo ){
  return isAdmin( userInfo ) || hasGroup( userInfo, "editor" );
}

export function isManager( userInfo ){
  return isAdmin( userInfo ) || hasGroup( userInfo, "manager" );
}

export function isUser( userInfo ){
  return userInfo && (
    isEditor( userInfo ) ||
    isManager( userInfo ) ||
    (hasGroup( userInfo, "user" ) && userInfo.formatAccess === 'user')
  );
}

export function isPreviewer( userInfo ){
  return userInfo && (
    isUser( userInfo ) ||
    (hasGroup( userInfo, "user" ) && userInfo.formatAccess === 'preview')
  );
}

export function isDisabled( userInfo ){
  return userInfo && !isPreviewer( userInfo );
}

/**
 * Render the children if it meets one of the following criteria
 * - The userinfo represents a user or editor and "user" is true
 * - The userinfo represents an editor and "editor" is true
 * - The userinfo has one of the groups listed in the groups
 * or the opposite if the "not" parameter is true
 * @param userInfo The userinfo object
 * @param user
 * @param editor
 * @param groups
 * @param not
 * @constructor
 */
export default function Auth({ userInfo, disabled=false, preview=false, user=false, editor=false, manager=false, admin=false, groups, not=false, ...props }){
  let shouldRender = false;
  shouldRender = disabled === true? isDisabled( userInfo )  : shouldRender;
  shouldRender = preview === true ? isPreviewer( userInfo ) : shouldRender;
  shouldRender = user === true    ? isUser( userInfo )      : shouldRender;
  shouldRender = manager === true ? isManager( userInfo )   : shouldRender;
  shouldRender = editor === true  ? isEditor( userInfo )    : shouldRender;
  shouldRender = admin === true   ? isAdmin( userInfo )     : shouldRender;
  shouldRender = groups && groups.length ?  hasGroup( userInfo, groups ) : shouldRender;
  if( not ){
    shouldRender = !shouldRender;
  }
  if( shouldRender ){
    return props.children;
  } else {
    return null;
  }
}
