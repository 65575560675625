import React, { useState, useEffect } from 'react';

import {withRouter} from "react-router-dom";
import {Navbar, Nav, NavDropdown, Form, Button, Dropdown} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Auth } from "aws-amplify";

import Routes from "./Routes";
import IsAuth, {
  isAdmin,
  isEditor,
  isManager,
  isPreviewer,
} from "./components/Auth";
import './App.css';

import Moment from "react-moment";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSync, faSpinner, faCheckCircle, faTimesCircle,
  faSearch, faCog,
  faInfo, faPlay, faPause, faPhotoVideo, faImage, faArrowDown, faChevronDown,
  faCopy, faPen, faTimes
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faSync, faSpinner, faCheckCircle, faTimesCircle,
  faSearch, faCog,
  faInfo, faPlay, faPause, faPhotoVideo, faImage, faArrowDown, faChevronDown,
  faCopy, faPen, faTimes
);

const categories = {
  "chr": [
    'Social Media',
    'Music',
    'Entertainment',
    'Lifestyle',
    'Obits'
  ],
  "country":  [
    'Social Media',
    'Music',
    'Entertainment',
    'Lifestyle',
    'Obits'
  ],
  "rock": [
    'Social Media',
    'Music',
    'Entertainment',
    'Lifestyle',
    'Obits'
  ],
  "urban": [
    'Social Media',
    'Music',
    'Entertainment',
    'Lifestyle',
    'Obits'
  ],
  "ALL": [
    'in progress',
    'awaiting review',
    'available'
  ]
};

export const formatTitles = {
  "chr": "CHR / Hot AC / AC"
}

const excludedFormats = ['ALL'];
export function validFormat( format ){
  return format && excludedFormats.indexOf( format ) === -1
}

function FormatSelector({userInfo,className,setFormat,...props}){
  if( !(userInfo && userInfo.formats) ){
    return null;

  } else {
    return(
      <Dropdown
        title="change format"
        as={Nav.Item}
        className={className}
      >
        <Dropdown.Toggle
          as={Nav.Link}
          className={`format ${Object.keys(userInfo.formats).length > 1 ? "format-multi" : "format-uni"}`}
        >
          {formatTitles[userInfo.format] || userInfo.format}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            Object.keys(userInfo.formats).map( format =>
              userInfo.formats[format] &&
              <NavDropdown.Item
                key={`format-${format}`}
                eventKey={format}
                onSelect={setFormat}
                active={userInfo.format === format}
              >
                {formatTitles[format] || format}
              </NavDropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

function AppNavbar({userInfo,setUserInfoState,search,setSearch,handleLogout,...props}){

  const [searchProgress,setSearchProgress] = useState( search );

  function onSubmit( e ){
    e.preventDefault();
    setSearch( searchProgress )
  }

  function onClear( e ){
    setSearch("");
    setSearchProgress("");
  }

  function linkTo( loc, e ){
    console.log(loc,e);
    e.preventDefault();
    setSearch('');
    setSearchProgress('');
    props.history.push(loc);
  }

  function setFormat( eventKey, event ){
    let newUserInfo = {
      ...userInfo,
      format: eventKey,
      formatAccess: userInfo.formats[eventKey]
    };
    console.log('setFormat',newUserInfo);
    setUserInfoState( newUserInfo );
  }

  return (
    <>
      <Navbar
        className="Topbar"
        variant="dark"
      >
        {isPreviewer(userInfo)
          ? <Navbar.Collapse className="justify-content-end">
            <Nav>
              <IsAuth editor userInfo={userInfo}>
                <Nav.Link href="/editor/file/new">Upload File</Nav.Link>
              </IsAuth>
              <IsAuth manager userInfo={userInfo}>
                <NavDropdown id="admin-dropdown" title="Admin">
                  <NavDropdown.Item href="/admin/user">
                    Users
                  </NavDropdown.Item>
                  {/*
                    <NavDropdown.Item href="/admin/log/summary">
                      Report
                    </NavDropdown.Item>
                  */}
                  <NavDropdown.Item href="/admin/report">
                    Reports
                  </NavDropdown.Item>
                </NavDropdown>
              </IsAuth>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="username">
                  {userInfo.username} &nbsp;
                  <FontAwesomeIcon icon="cog"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <NavDropdown.Item href="/user/logo">
                    Custom Logo
                  </NavDropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link onClick={handleLogout}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          : <Navbar.Collapse className="justify-content-end">
            <Nav>
              <LinkContainer to="/login">
                <Nav.Link>Log In</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        }
      </Navbar>
      <Navbar
        className="AppNavbar"
        collapseOnSelect
        variant="dark"
        expand="md"
      >
        <Navbar.Brand href="/">
          <img src="/img/power-bar-v01-849x116.png" alt="ABC PowerPortal"/>
        </Navbar.Brand>
        <FormatSelector userInfo={userInfo} setFormat={setFormat} className="format-desktop"/>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {userInfo && userInfo.groups && userInfo.format
              ? <>
                <FormatSelector userInfo={userInfo} setFormat={setFormat} className="format-mobile"/>
                <Nav.Item>
                  <Nav.Link href="/">Latest</Nav.Link>
                </Nav.Item>
                <NavDropdown id="nav-category" title="Category">
                  {categories[userInfo.format].map( category =>
                    <NavDropdown.Item key={category} href={`/?category=${category}`}>
                      {category}
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                <LinkContainer exact to="/faq">
                  <Nav.Link>FAQ</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/contact">
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
                <Form inline className="search" size="sm" onSubmit={onSubmit}>
                  <Form.Control
                    value={searchProgress}
                    placeholder="SEARCH"
                    size="sm"
                    onChange={e => setSearchProgress(e.target.value)}
                  />
                  <Button size="sm" variant="outline-dark" onClick={onClear} disabled={searchProgress.length === 0}>
                    <FontAwesomeIcon icon="times-circle"/>
                  </Button>
                </Form>
              </>
              : <>
                <LinkContainer exact to="/faq">
                  <Nav.Link>FAQ</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/contact">
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
              </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

function Footer({autoLock=false,...props}){

  const height = 90;  // TODO: Compute this

  const [lock,setLock] = useState(false);
  const [lockPos, setLockPos] = useState();
  const [bottom,setBottom] = useState(0);

  useEffect(() => {

    function onScroll( e ){

      if( !autoLock ){return}

      const distance = window.innerHeight + document.documentElement.scrollTop;
      const bottom = document.documentElement.offsetHeight;
      const gap = bottom - distance;

      if( gap < 5 ){
        setLock( true );
        setLockPos( distance );
      }

      if( lock ){
        let lockGap = lockPos - distance;
        if( distance > lockPos ){
          setLockPos( distance );

        } else if( lockGap > 90 ){
          setLock( false );
          setBottom( 0 );

        } else {
          let newBottom = -Math.min( lockGap, height );
          setBottom( newBottom );
          console.log('newBottom',newBottom);
        }
      }

    }

    window.addEventListener( "scroll", onScroll );

    return () => {
      window.removeEventListener( "scroll", onScroll );
    }
  });

  const style = {
    position: lock ? "fixed" : "absolute",
    bottom: `${bottom}px`
  };
  return(
    <footer className="footer" id="footer" style={style}>
      <div>
        <img src="/img/abcaudiologo.png" alt="ABC Audio"/>
        <br/>
        Copyright <Moment format="YYYY"/> ABC Audio |
        <a href="http://disneytermsofuse.com/"> Terms of Use</a> |
        <a href="https://disneyprivacycenter.com/"> Privacy Policy</a> |
        <a href="https://disneyprivacycenter.com/notice-to-california-residents/"> Your California Privacy Rights</a> |
        <a href="https://disneyprivacycenter.com/kids-privacy-policy/english/"> Children's Online Privacy Policy</a>
      </div>
    </footer>
  );
}

function App(props) {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [search, setSearch] = useState("");

  const [userInfo, setUserInfoStateRaw] = useState(null);

  const storage = window.localStorage;

  function setUserInfoState( userInfo ){
    setUserInfoStateRaw( userInfo );

    if( userInfo && userInfo.username ){
      storage.setItem( "username", userInfo.username );
      storage.setItem( "format", userInfo.format );
    }
  }

  function setUserInfo( idToken ){
    const username = idToken.payload['cognito:username'];
    const groups = idToken.payload['cognito:groups'];
    let info = {
      username: username,
      groups: groups
    };

    const iss = idToken.payload['iss'];
    const issPath = iss.indexOf( 'https://' ) === 0 ? iss.substring(8) : iss;
    const sub = idToken.payload['sub'];
    const userid = `${issPath},${issPath}:CognitoSignIn:${sub}`;
    info = {
      ...info,
      iss,
      sub,
      userid
    }

    const formats = determineFormats( info, idToken.payload );
    const format =
      loadFormatFromStorage( username, formats ) ||
      determineFormat( 'user', formats ) ||
      determineFormat( 'preview', formats );
    info = {
      ...info,
      formats: formats,
      format: format,
      formatAccess: formats[format]
    };
    console.log('setUserInfo',info);
    setUserInfoState(info);
  }

  function loadFormatFromStorage( username, userFormats ){
    const storageName = storage.getItem( "username" );
    // This was not the username in storage - don't use it
    if( username !== storageName ){
      return null;
    }

    const storageFormat = storage.getItem( "format" );
    if( !userFormats[storageFormat] ){
      // They aren't permitted to this format anymore - don't use it
      return null;
    }

    return storageFormat;
  }

  function determineFormat( level, formats ){
    return Object.keys( formats ).reduce( (ret, format ) => {
      if( ret ){
        return ret;

      } else if( formats[format] === level ){
        return format;

      } else {
        return "";
      }
    }, "" );
  }

  function determineFormats( protoinfo, payload ){
    console.log('payload',payload);
    let ret = {};

    let powerUser = isAdmin(protoinfo) || isManager(protoinfo) || isEditor(protoinfo);

    const formats = Object.keys(categories);
    formats.forEach( format => {
      const payloadName = `custom:format-${format}`;

      let val;
      if( powerUser ){
        // Admin-types should see all of the formats
        val = "user";

      } else {
        // User types should only see what they're specifically permitted to
        val = payload[payloadName];
      }

      if( val ){
        ret[format] = val;
      }
    });

    return ret;
  }

  // Check the user's authentication state *only* on the first load.
  useEffect(() => {
    async function onLoad() {
      try {
        let session = await Auth.currentSession();
        console.log('session',session);
        const credentials = await Auth.currentUserCredentials();
        console.log('credentials',credentials);
        setUserInfo( session.getIdToken() );
      }
      catch(e) {
        if (e !== 'No current user') {
          console.log('auth state error',e);
        }
      }

      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  useEffect(() => {
    const footer = document.getElementById("footer");
    if( footer ){
      console.log("footer height",footer.offsetHeight,footer.clientHeight,footer.getBoundingClientRect());
    }
  });

  async function handleLogout() {
    await Auth.signOut();
    setUserInfoState( null );
    props.history.push( "/" );
  }

  return (
    !isAuthenticating &&
    <>
      <AppNavbar
        search={search} setSearch={setSearch}
        userInfo={userInfo} setUserInfoState={setUserInfoState}
        handleLogout={handleLogout} {...props}/>
      <div className="App container">
        <Routes appProps={{userInfo, setUserInfo, setUserInfoState, categories, search}}/>
      </div>
      <Footer/>
    </>
  );
}

export default withRouter(App);
