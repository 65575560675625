import React, {useState} from "react";

import { WithContext as ReactTags } from 'react-tag-input';

import './TagInput.css';

const KeyCodes = {
  tab:     9,
  enter:  13,
  space:  32,
  comma: 188,
};

const delimiters = Object.values( KeyCodes );

const classNames={
  tags: 'tags',
  tagInput: 'tagInput',
  tagInputField: 'tagInputField',
  selected: 'selected',
  tag: 'tag',
  remove: 'remove',
  suggestions: 'suggestions',
  activeSuggestion: 'activeSuggestion'
};

export default function TagInput({tags=[], setTags, ...props}){
  console.log('TagInput tags',tags);
  const initialTagInfo = tags.map( t => {return {id:t,text:t}} );
  const [tagInfo,setTagInfo] = useState(initialTagInfo);

  function setTagsAndTagInfo( tagInfo ){
    setTagInfo( tagInfo );
    setTags( tagInfo.map( info => info.text ) );
  }

  function handleAddition( tag ){
    console.log( tag );
    if( !tag.text.startsWith('#') ){
      tag.text = `#${tag.text}`;
    }
    tagInfo.push( tag );
    setTagsAndTagInfo( tagInfo );
  }

  function handleDelete( i ){
    let newTags = tagInfo.filter((tag, index) => index !== i);
    setTagsAndTagInfo( newTags );
  }

  return(
    <div className="TagInput">
      <ReactTags
        tags={tagInfo}
        delimiters={delimiters}
        handleAddition={tag => handleAddition(tag)}
        handleDelete={index => handleDelete(index)}
        inputFieldPosition="inline"
        allowDragDrop={false}
        classNames={classNames}
        autofocus={false}
        />
    </div>
  );
}