import React, {useContext, useState} from "react";
import LoaderButton from "../components/LoaderButton";
import Auth from "../components/Auth";
import "./Ping.css";
import Squares from "../components/spinner/Squares";
import BarsRotate from "../components/spinner/BarsRotate";
import {ApiContext} from "../components/ApiContext";

export default function Ping( props ){

  const [isLoading, setIsLoading] = useState(false);
  const api = useContext( ApiContext );

  async function ping(){
    return api.get( "media", "/ping" );
  }

  async function doPing( event ){
    console.log('doPing');
    event.preventDefault();
    setIsLoading(true);

    try {
      let result = await ping();
      console.log(result);
    } catch( err ){
      console.error( err );
    }

    setIsLoading(false);
  }

  async function whoAmI(){
    return api.get( "media", "/whoami" );
  }

  async function doWhoAmI( event ){
    console.log('doWhoAmI');
    event.preventDefault();
    setIsLoading(true);

    try {
      let result = await whoAmI();
      console.log( result );
    } catch( err ){
      console.error( err );
    }

    setIsLoading(false);
  }

  return (
    <div className="Ping">
      <LoaderButton
        block
        isLoading={isLoading}
        onClick={doPing}
      >
        Ping
      </LoaderButton>
      <Auth preview userInfo={props.userInfo}>
        <LoaderButton
          block
          isLoading={isLoading}
          onClick={doWhoAmI}
        >
          Who Am I
        </LoaderButton>
      </Auth>
      <div className="spinner">
        <Squares/>
      </div>
      <div className="spinner">
        <BarsRotate/>
      </div>
    </div>
  );
}