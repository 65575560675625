import React, {useCallback, useEffect} from "react";

export default function Vid({
  src,
  thumb,
  started,
  setStarted,
  playing,
  setPlaying,
  videoId,
  className="",
  ...props
}){

  const onStart = useCallback( e => {
    setStarted( true );
    setPlaying( true );
  },[setStarted,setPlaying]);

  const onPause = useCallback( e => {
    setPlaying( false );
  },[setPlaying]);

  const onEnded = useCallback( e => {
    setStarted( false );
    setPlaying( false );
  },[setStarted,setPlaying]);

  const onEvent = useCallback( e => {
    console.log( "Vid event", e );
    switch( e.type ){
      case "start": return onStart( e );
      case "pause": return onPause( e );
      case "ended": return onEnded( e );
      default:
    }
  }, [onStart,onPause,onEnded] );

  useEffect(()=>{
    console.log('Vid init');
    return(()=>{
      console.log('Vid cleanup');
    })
  });

  useEffect(() => {
    console.log("Started effect: ", started);
    let vid = document.getElementById(videoId);
    if( started ){
      console.log(vid);
      if( vid ){
        vid.addEventListener( "play", onEvent );
        vid.addEventListener( "pause", onEvent );
        vid.addEventListener( "ended", onEvent );
      }
    }

    return () => {
      console.log("Started effect cleanup",vid);
      if( vid ){
        vid.removeEventListener( "play", onEvent );
        vid.removeEventListener( "pause", onEvent );
        vid.removeEventListener( "ended", onEvent );
      }
    };
  }, [started,videoId,onEvent]);

  useEffect(() => {
    const video = document.getElementById(videoId);
    console.log( "Playing effect:", playing, video );

    if( !video ){
      return;
    }

    if( playing ){
      video.play();
    } else {
      video.pause();
    }

    return(()=>{
      console.log("Playing effect cleanup");
    })
  }, [playing,videoId]);

  useEffect(() => {
    console.log("Rendered effect");
  });

  return (
    <div className={`Vid ${className}`}>
      {started
        ? <video key={videoId} id={videoId} src={src} autoPlay />
        : <img src={thumb} alt="Video thumbnail" onClick={onStart}/>
      }
    </div>
  );
}