import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import AppliedRoute from "../../components/AppliedRoute";
import Admin from "./Admin";
import UserList from "./UserList";
import UserSummary from "./UserSummary";
import {User,UserNew} from "./User";
import {isManager} from "../../components/Auth";
import NotFound from "../NotFound";
import UserLog from "./UserLog";
import Reports from "./Reports";
import ReportView from "./ReportView";

export default function Routes(props){
  let { path } = useRouteMatch();

  function AdminRoutes(){
    return(
      <div className="Admin">
        <Switch>
          <AppliedRoute path={path}                     exact component={Admin} appProps={props} />
          <AppliedRoute path={`${path}/user`}           exact component={UserList} appProps={props} />
          <AppliedRoute path={`${path}/user/new`}       exact component={UserNew} appProps={props} />
          <AppliedRoute path={`${path}/user/:username`} exact component={User} appProps={props} />
          <AppliedRoute path={`${path}/log/user/:username`} exact component={UserLog} appProps={props} />
          <AppliedRoute path={`${path}/log/summary`}    exact component={UserSummary} appProps={props} />
          <AppliedRoute path={`${path}/report`}         exact component={Reports} appProps={props} />
          <AppliedRoute path={`${path}/report/`}              component={ReportView} appProps={props} />
        </Switch>
      </div>
    );
  }

  return(
    isManager( props.userInfo )
    ? <AdminRoutes/>
    : <NotFound {...props}/>
  );
}