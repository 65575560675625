import React from "react";

export default function Duration({ms,...props}){
  let sec = Math.round( ms / 1000 );
  let mm = Math.trunc( sec / 60 );
  let ss = sec % 60;
  if( ss < 10 ){
    ss = `0${ss}`;
  }
  return (
    <span className="Duration">{mm}:{ss}</span>
  );
}
