import React, {useCallback, useState} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./TableSearch.css";

export function TableSearch({delay=250, onSearch, ...props}){
  const [value, setValue] = useState("");
  const [delayHandler,setDelayHandler] = useState( 0 );

  const changed = useCallback( e => {
    //console.log('changed',e.target.value);
    let targetValue = e.target.value;
    setValue( targetValue );
    if( delayHandler ){
      clearTimeout( delayHandler );
      setDelayHandler( 0 );
    }
    let handler = setTimeout( () => {
      //console.log('search timeout',targetValue);
      setDelayHandler( 0 );
      onSearch( targetValue );
    }, delay );
    setDelayHandler( handler );
  }, [value, delayHandler,onSearch]);

  function onClear( e ){
    setValue("");
    setDelayHandler(0);
    onSearch( "" );
  }

  return (
    <div className="TableSearch">
      <input type="text" value={value} onChange={changed} placeholder="search"/>
      <Button size="sm" variant="light" onClick={onClear} disabled={value.length === 0}>
        <FontAwesomeIcon icon="times-circle"/>
      </Button>
    </div>
  )
}